import React from 'react';
import { useNavigate } from 'react-router-dom';
import imgDefault from '../../assets/Avatar.png'

const Data = [
  {
    img: "https://mdbootstrap.com/img/new/avatars/8.jpg",
    name: "John Doe",
    email: "john.doe@gmail.com",
    company: "Virtual Employee Pvt Ltd",
    label: "Software engineer",
    department: "IT department",
    experience: "6 Year",
    status: "In Job",
    skills: ['html', 'css', 'React']
  },
  {
    img: imgDefault,
    name: "Alex Ray",
    email: "alex.ray@gmail.com",
    company: "Virtual Employee Pvt Ltd",
    label: "Consultant",
    department: "Finane",
    experience: "9 Year",
    status: "On Notice Period",
    skills: ['html', 'css', 'React']
  },
  {
    img: "https://mdbootstrap.com/img/new/avatars/8.jpg",
    name: "Kate Hunington",
    email: "kate.hunington@gmail.com",
    company: "Virtual Employee Pvt Ltd",
    label: "Designer",
    department: "UI/UX",
    experience: "4 Year",
    status: "No Where Working",
    skills: ['html', 'css', 'React']
  },
  {
    img: imgDefault,
    name: "Adam Cuill",
    email: "adam.quill@gmail.com",
    company: "Fresher",
    label: "Software engineer",
    department: "IT department",
    experience: "6 Year",
    status: "Fresher",
    skills: ['html', 'css', 'React']
  },
  {
    img: "https://mdbootstrap.com/img/new/avatars/8.jpg",
    name: "John Doe",
    email: "john.doe@gmail.com",
    company: "Virtual Employee Pvt Ltd",
    label: "Software engineer",
    department: "IT department",
    experience: "6 Year",
    status: "In Job",
    skills: ['html', 'css', 'React']
  },
  {
    img: imgDefault,
    name: "Alex Ray",
    email: "alex.ray@gmail.com",
    company: "Virtual Employee Pvt Ltd",
    label: "Consultant",
    department: "Finane",
    experience: "9 Year",
    status: "On Notice Period",
    skills: ['html', 'css', 'React']
  },
  {
    img: "https://mdbootstrap.com/img/new/avatars/8.jpg",
    name: "Kate Hunington",
    email: "kate.hunington@gmail.com",
    company: "Virtual Employee Pvt Ltd",
    label: "Designer",
    department: "UI/UX",
    experience: "4 Year",
    status: "No Where Working",
    skills: ['html', 'css', 'React']
  },
]

export const Dashboard = () => {
  const navigate = useNavigate();

  const CandidateTestScreen = (info) => {
    navigate('/candidate-tests', { state: info });
  }

  return (
    <div className='dashboard-home py-4 py-xl-5'>
      <div className='container'>
        <div className='calender-sec mb-4'>
          <div className='row align-items-center'>
            <div className='col-md-7'>
              <div className="fs-2 fw-bold">Total Candidates: <span className='text-success'>26</span></div>
            </div>
            <div className='col-md-5 mt-2 mt-md-0'>
              <div className="d-md-flex align-items-center gap-md-3">
                <label className="form-label text-nowrap fw-bold m-md-0" htmlFor='calender'>Select Date</label>
                <input type="date" className="form-control py-2" id="calender" name="calender" />
              </div>
            </div>
          </div>
        </div>
        <div className='candidate-listing-sec bg-white rounded px-3 px-md-4 py-4 mt-4'>
          <div className='candidates-wrapper'>
            <div className='candidate-list table-responsive border rounded-3'>
              <table className="table align-middle text-nowrap mb-0">
                <thead className="bg-primary text-light sticky-top">
                  <tr>
                    <th scope="col">
                      <input type="checkbox" className="form-check-input" id="mastercheck" />
                    </th>
                    <th scope="col">Candidate Name</th>
                    <th scope="col">Company Name</th>
                    {/* <th scope="col">Job Title</th> */}
                    <th scope="col">Experience</th>
                    <th scope="col">Status</th>
                    <th scope="col">Prefered Language</th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((info, id) => (
                    <tr className='pointer' key={id} onClick={() => CandidateTestScreen(info)}>
                      <td>
                        <input type="checkbox" className="form-check-input" id="checkbox" />
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <img src={info.img} alt="Candidate" className="border rounded-circle" style={{ width: '45px', height: '45px' }} />
                          <div className="ms-2">
                            <div className="cand-name fw-semibold text-capitalize">{info.name}</div>
                            <div className="cand-email text-muted">{info.email}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="company-name text-capitalize">{info.company}</div>
                      </td>
                      {/* <td>
                        <div className="cand-label text-capitalize">{info.label}</div>
                        <div className="cand-department text-muted text-capitalize">{info.department}</div>
                      </td> */}
                      <td><div className="cand-exp text-capitalize">{info.experience}</div></td>
                      <td>
                        <span className={`badge rounded-pill ${info.status === 'In Job' ? 'text-bg-success' : 'text-bg-secondary' && info.status === 'On Notice Period' ? 'text-bg-danger' : 'text-bg-success' && info.status === 'No Where Working' ? 'text-bg-warning' : 'text-bg-secondary'}`}>{info.status}</span>
                      </td>
                      <td>
                        <div className="test-lang d-flex flex-wrap gap-2">
                          {info.skills.map((name, id) => (
                            <span className="skills" key={id}>{name}</span>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
