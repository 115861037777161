// Screen.js
import React, { useContext } from 'react';
import ProfileContext from '../context/ProfileContext';

const Screen = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <div className='screen-page py-5'>
      <div className='container'>
        <div className='screen-area'>
          <div className='heading-title fs-2 fw-bold mb-3 text-center'>Screen</div>
          <div className='profile-form-wrapper mx-auto card py-4 px-3 p-md-5 shadow-sm'>
            <p><strong>Current Company: </strong>{profile.currentCompany}</p>
            <p><strong>Total Experience: </strong>{profile.totalExperience} years</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen;
