import axios from "axios";
import React, { useEffect, useState, } from "react";
import { connect } from "twilio-video";
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { app, database, auth, getAuth } from "../firebase";

import {
    collection,
    addDoc,
    doc,
    setDoc,
    getDocs,
    query,
    where,
    onSnapshot,
    orderBy,
    updateDoc,
    limit,
    serverTimestamp,
    getDoc,
  } from "firebase/firestore";
export const Mobile = (props) => {
    const search = useLocation().search;
    const navigate = useNavigate();

    const identityVal = new URLSearchParams(search).get("identity");
    const roomIdVal = new URLSearchParams(search).get("rmid");
    const roomUid = new URLSearchParams(search).get("uid");

    const identity = identityVal ? atob(identityVal) : null;
    const roomId = roomIdVal ? atob(roomIdVal) : null;
    const rmroomId = roomUid ? atob(roomUid) : null;
    const constraints = {
        video: { facingMode: "environment" },
    };
    const mobileDevice = [];
    const [show, setShow] = useState(true);
    const [mobileDeviceSettings, setMobileDeviceSettings] = useState([]);
    const [roominfo, setRoom] = useState(null);
    const [battery, setBattery] = useState(100);
    const [isCharging, setCharging] = useState(false);

    const [rearCameraStatus, setRearCamera] = useState('');
    const [networkInfo, setNetworkInfo] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [screenWidth, setScreenWidth] = useState('');
    const [screenHeight, setScreenHeight] = useState('');
    const [deviceOrientation, setOrientation] = useState('');
    const [pageVisibility, setpageVisibility] = useState(true);
    const [isOnline, setIsOnline] = useState(navigator.onLine);


    useEffect(() => {
      function handleOnlineStatusChange() {
        setIsOnline(navigator.onLine);
      }
  
      window.addEventListener('online', handleOnlineStatusChange);
      window.addEventListener('offline', handleOnlineStatusChange);
  
      return () => {
        window.removeEventListener('online', handleOnlineStatusChange);
        window.removeEventListener('offline', handleOnlineStatusChange);
      };
    }, []);
    useEffect(()=>{
        updateMobileDeviceSettings();
    },[battery,isCharging,pageVisibility])

    const updateMobileDeviceSettings = async ()=>{
           // userDevices
    //    let roomId = "SSUDS";
    //    let identity = "DS";
        if(rmroomId && identity)
       {

        const docRef = doc(database,"userDevices",rmroomId);
        const docSnap = await getDoc(docRef);
           // console.log(docSnap.data());
        if(docSnap.exists()){
            const data = {
                identity: identity,
                battery: battery,
                isCharging: isCharging,
                rearCameraStatus: rearCameraStatus,
                networkInfo: networkInfo,
                latitude: latitude,
                longitude: longitude,
                deviceOrientation: deviceOrientation,
                pageVisibility:pageVisibility,
                isOnline: isOnline,
                timeStamp: serverTimestamp(),
              };
           
            updateDoc(docRef,data)
            .then(() => {
            //    console.log("Updated");
             })
            .catch(error => {
               console.log("Error",error);
               
             })
        }
        else{
            const data = {
                identity: identity,
                battery: battery,
                isCharging: isCharging,
                rearCameraStatus: rearCameraStatus,
                networkInfo: networkInfo,
                latitude: latitude,
                longitude: longitude,
                deviceOrientation: deviceOrientation,
                pageVisibility:pageVisibility,
                isOnline: isOnline,
                timeStamp: serverTimestamp(),
              };
            setDoc(docRef, data)
  .then(() => {
    // console.log('Document successfully written with custom ID: ' + roomId);
  })
  .catch((error) => {
    // console.error('Error writing document: ', error);
  });
        }  
       }
    } 

    navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
            const video = document.querySelector("video");
            video.srcObject = mediaStream;
            video.onloadedmetadata = () => {
                video.play();
            };
        })
        .catch((err) => {
            // always check for errors at the end.
            console.error(`${err.name}: ${err.message}`);
        });

        useEffect(() => {
            const handleVisibilityChange = () => {
              if (document.hidden) {
                setpageVisibility(false);
              } else {
                setpageVisibility(true);
              }
            };
          document.addEventListener('visibilitychange', handleVisibilityChange);
            return () => {
              document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
          }, []);
    async function generateToken() {

        const response = await axios.get(
            `https://live-work-3589-dev.twil.io/token-service?identity=${identity}`
        );
        const data = response.data;
        return data.accessToken;

    }

    async function startRoom(roomId) {
        // document.getElementById('form2').style.display = "block";
        const roomName = roomId;
        const token = await generateToken();
        toast.warning('Please wait we are connecting...', {
            position: toast.POSITION.BOTTOM_CENTER
        });
        // join the video room with the token
        const room = await joinVideoRoom(roomName, token);
        setRoom(room);
        // render the local and remote participants' video and audio tracks
        handleConnectedParticipant(room.localParticipant);
        room.participants.forEach(handleConnectedParticipant);
        room.on("participantConnected", handleConnectedParticipant);

        // handle cleanup when a participant disconnects
        room.on('participantDisconnected', (participant) => {
            handleDisconnectedParticipant(participant,room);
        
          });


        window.addEventListener("pagehide", () => room.disconnect());
        window.addEventListener("beforeunload", () => room.disconnect());
    }

    async function handleConnectedParticipant(participant) {
         participant.tracks.forEach((trackPublication) => {
            handleTrackPublication(trackPublication, participant);
        });

        // listen for any new track publications
        participant.on("trackPublished", handleTrackPublication);
    };

    async function handleTrackPublication(trackPublication, participant) {
        function displayTrack(track) {
            try {
                track.setTrackName("Mobile_Track");
                //display track section
            } catch (err) {
                console.log(err);
            }

        }
        if (trackPublication.track) {
            displayTrack(trackPublication.track);
        }

        // listen for any new subscriptions to this track publication
        trackPublication.on("subscribed", displayTrack);
    };

    async function handleDisconnectedParticipant(participant,room) {
        if (!participant.identity.includes('Admin_')) {
        participant.removeAllListeners();
        if (room) {
            room.disconnect();
            navigate('/screen');
        }
    }
}

    async function joinVideoRoom(roomName, token) {
        const room = await connect(token, {
            name: roomName,
            audio: false,
            video: { facingMode: "environment" }
        });
        return room;
    };

    const joinRoom = () => {
       if(battery < 40 && isCharging == false)
       {
        toast.error('Sorry you phone Battery is less than 40% and your phone is not charging, Please connect with charger and refresh this page before streaming!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
       }
       else
       {
        if(roomId)
        startRoom(roomId);
    else
    toast.error('Unable to connect you with Desktop App !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
       }        
    }
    useEffect(() => {
        if (navigator.getBattery) {
            navigator.getBattery().then(function (battery) {
                function updateBatteryStatus() {
                 
                    setBattery((battery.level * 100));
                    setCharging(battery.charging);
                    updateMobileDeviceSettings();
                }
                battery.addEventListener('levelchange', updateBatteryStatus);
                updateBatteryStatus();
            });
        } else {
        }
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                
                setRearCamera(true);
              
            }).catch(function (error) {
                setRearCamera(false);
            });
        }
        if (navigator.connection) {
            setNetworkInfo(navigator.connection.effectiveType);  
        } else {
            setNetworkInfo("");
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            }, function (error) {

               
            });
        } 
        setScreenWidth(window.screen.width);
        setScreenHeight(window.screen.height);
       
       }, [])

       useEffect(() => {
        const handleOrientationChange = (event) => {
          if (event.matches) {
            setOrientation('Landscape');
          } else {
            setOrientation('Portrait');
          }
        };
    
        const mediaQuery = window.matchMedia('(orientation: landscape)');
        mediaQuery.addListener(handleOrientationChange);
        handleOrientationChange(mediaQuery);
    
        return () => {
          mediaQuery.removeListener(handleOrientationChange);
        };
      }, []);
    
    return (
        <>
            <div className="mobile-stream-page">
                <div className="top-header bg-primary sticky-top py-2">
                    <a class="navbar-brand d-flex align-items-center justify-content-center p-0" href="/">
                        <div class="brand-name">NM-Interview System</div>
                        <div class="app-version d-grid gap-1">
                            <div>BETA</div>
                            <div>Version 1.1.5</div>
                        </div>
                    </a>
                </div>
                <div className="container-xl p-0">
                    <div className="custom-wrapper mx-auto" style={{ maxWidth: '700px' }}>
                        <div className="preview-wrapper ratio ratio-4x3 overflow-hidden position-relative">
                            <div id="video-preview" className="object-fit-cover"></div>
                            <video className="w-100 object-fit-cover" width="640" height="480" id="rearVideo" muted playsInline></video>

                            <div className="relative-wrapper text-center p-3">
                                <Alert show={show} variant="info" onClose={() => setShow(false)} dismissible className="small mx-auto" id="networkInfo" style={{ maxWidth: '350px' }}>
                                    <p className="m-0">Please Ajdust your Camera so you are your system should be visible, and click on green button to start streaming.</p>
                                </Alert>

                                <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2">
                                    {roominfo ?
                                        <button className="btn btn-danger focus-ring focus-ring-danger rounded-pill" title="Stop Streaming" onClick={() => {
                                            if (roominfo) {
                                                // alert(JSON.stringify(roominfo));
                                                roominfo.disconnect();
                                                navigate('/screen');
                                            }
                                        }}><i class="bi bi-stop-circle-fill"></i> Stop Streaming
                                        </button> :
                                        <button className="btn btn-success focus-ring focus-ring-success rounded-pill" title="Start Streaming" onClick={joinRoom}><i class="bi bi-play-circle-fill"></i> Start Streaming</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

