import React, { useEffect, useState, useContext } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ProfileContext from '../context/ProfileContext';
import AWS from 'aws-sdk';
import moment from 'moment';
import axios from 'axios';
const Account = () => {

  const navigate = useNavigate();

  const { user, logOut } = UserAuth();
  const { profile, setProfile } = useContext(ProfileContext);
  const [testData, setTestData] = useState([]);
  const [isVerified, setVerification] = useState(false);
  const [isOtherVerified, setOtherVerified] = useState(false);
  const [isEducationVerified, setEducationVerified] = useState(false);
  const [isHeadlineVerified, setHeadlineVerified] = useState(false);

 
  //Fetch UserInfo
  const fetchUserInfo = async () => {
    try {
      
      const refreshedToken = await user?.getIdToken(true);
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json',
      };
  
      const response = await axios.post(process.env.REACT_APP_APIURL, {
        operation: 'fetchuser',
        email: user?.email,
      }, {
        headers: headers,
      });
  
      const userData = response.data[0];
      const InterviewerInfo = {
        ...profile,
        prefered_lang: userData?.prefered_lang,
        is_profile_verified: userData?.is_video_verified,
      };
  
      setVerification(userData?.is_video_verified === 1);
      setOtherVerified(userData?.is_employement_added === 1);
      setEducationVerified(userData?.is_edu_added === 1);
      setHeadlineVerified(userData?.resume_headline_added === 1);
  
      setProfile(InterviewerInfo);
    } catch (error) {
      console.error(error);
    }
  };
  

  useEffect(() => {
    if(user?.accessToken)
    {
      fetchUserInfo();
      fetchtestResult();
    }
  }, [user])


  //fetchtest
  const fetchtestResult = async () => {
    try {
      const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json',
      };
  
      const response = await axios.post(process.env.REACT_APP_APIURL, {
        operation: 'fetchtest',
        email: user?.email,
      }, {
        headers: headers,
      });
  
      const dataVal = response.data;
      if (dataVal?.length === 0) {
        // Handle empty data case if needed
      } else {
        setTestData(dataVal);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ProfileVerifyPage = () => {
    if(!isVerified)
    navigate('/profile-verify');
    else if(!isOtherVerified || !isEducationVerified || !isHeadlineVerified )
    navigate('/complete-profile');
    else
    navigate('/');
  }

  return (
    <div className='profile-page pt-4 pb-5'>
      <div className='container'>
        <div className='text-success fs-3 fw-semibold mb-2'><strong>Welcome,</strong> {user?.displayName}</div>
        <div className='verify-btn-wrapper d-flex gap-3 mb-4'>
          {!isVerified || !isOtherVerified || !isEducationVerified || !isHeadlineVerified ?
            <button className='btn btn-danger text-capitalize' onClick={ProfileVerifyPage}><i className="bi bi-check-circle-fill me-1"></i>Verify Your Profile</button> :
            <button className='btn btn-danger text-capitalize'><i className="bi bi-check-circle-fill me-1"></i>Profile is already validated</button>
          }
        </div>

        <div className='download-app-sec text-center text-lg-start bg-light rounded-3 px-3 px-md-5 py-5'>
          <div className='row align-items-center'>
            <div className='col-lg-6 mb-5 mb-lg-0 pe-lg-4'>
              <div className='fs-2 fw-bold mb-3'>Download for Windows</div>
              <p className='description text-muted mb-4 pb-2'>Download and install exe file to your computer and use the same registered mobile number to start your online Test.You need to verify your profile so you can get download Link.</p>
              <a href="https://electronbuild.s3.ap-south-1.amazonaws.com/NMinterview_latest.exe" download="NM-Interview App" title='Download NM-Interview App'>
                {isVerified && isOtherVerified && isEducationVerified && isHeadlineVerified?
                  <button className='btn btn-primary fs-5 fw-medium px-5 py-3'><i className="bi bi-cloud-download"></i> Download App Now</button>
                  : null
                }
              </a>
              {/* <div className='app-version mt-2 small'>Currently <strong>v5.3.0</strong></div> */}
            </div>
            <div className='col-lg-6'>
              <img src={require('../assets/interview.webp')} className='img-fluid' alt='Interview' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;