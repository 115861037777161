import React from 'react';
import { useNavigate } from 'react-router-dom';

// Import slick slider files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const AdminLogin = () => {
    const navigate = useNavigate();

    const DashboardScreen = () => {
        navigate('/dashboard');
    }

    var settings = {
        arrows: false,
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className='authentication-page d-flex align-items-center py-3'>
            <div className='container'>
                <div className='auth_page bg-white rounded-4 m-auto pe-md-0'>
                    <div className='row g-0 align-items-center'>
                        <div className='col-md-5'>
                            <div className='left-section d-flex flex-column bg-primary text-white rounded-3 h-100'>
                                <div className='logo-title text-uppercase small fw-bold mb-4 mb-lg-5'>
                                    <img className='img-fluid rounded-3' src={require('../../assets/virtual-logo.png')} alt='Logo' />
                                </div>
                                <div className='heading-title fs-2 fw-bold mb-4'>Start Your<br /> Journey With Us.</div>
                                <div className='heading-subtitle'>Connect with the leading alliance of tech specialists and entrepreneurs, shaping the future of IT services.</div>

                                <div className='slider-wrapper pt-4 pt-lg-5 mt-auto'>
                                    <Slider {...settings}>
                                        <div className='testimonial-cards h-100 rounded-4 p-3'>
                                            <p className='description m-0'>Experience a live interview and seize the chance to secure a job offer.</p>
                                            <div className='d-flex align-items-center gap-2'>
                                                {/*<div className='img-chip'>
                                                    <img className='img-fluid rounded-2 border border-light' src={require('../assets/Avatar.png')} alt='User' />
                                                    </div>
                                                    <div className='chip-details'>
                                                    <div className='user-name fw-bold'>VirtualEmployee</div>
                                                    <div className='user-profile'></div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='testimonial-cards h-100 rounded-4 p-3'>
                                            <p className='description m-0'>Grab your chance to earn the best salaries the market has to offer.</p>
                                            <div className='d-flex align-items-center gap-2'>
                                                {/*<div className='img-chip'>
                                                    <img className='img-fluid rounded-2 border border-light' src={require('../assets/Avatar.png')} alt='User' />
                                                    </div>
                                                    <div className='chip-details'>
                                                    <div className='user-name fw-bold'>VirtualEmployee</div>
                                                    <div className='user-profile'></div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='testimonial-cards h-100 rounded-4 p-3'>
                                            <p className='description m-0'>Enhance and evaluate your skills using the premier self-assessment tool in the industry.</p>
                                            <div className='d-flex align-items-center gap-2'>
                                                {/* <div className='img-chip'>
                                                    <img className='img-fluid rounded-2 border border-light' src={require('../assets/Avatar.png')} alt='User' />
                                                    </div> 
                                                    <div className='chip-details'>
                                                    <div className='user-name fw-bold'>VirtualEmployee</div>
                                                    <div className='user-profile'></div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className='right-section h-100 py-4 py-lg-5 px-1 px-md-4 px-lg-5 p-xxl-5'>
                                <h5 className='fs-5 fw-bold'>Admin Login</h5>
                                {/* <div className='subtitle small'>Have an account? <a className='text-primary'>Sign In</a></div> */}
                                <div className='subtitle small'>Only admin are able to login here</div>
                                <form className='mt-4'>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Username</label>
                                        <input type="text" className='form-control py-2 rounded-1' placeholder="Enter your email" id="username" />
                                        <div className="small text-danger mt-1"></div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Password</label>
                                        <input type="password" className='form-control py-2 rounded-1' placeholder="Enter password" id="password" />
                                        <div className="small text-danger mt-1"></div>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                        <label class="form-check-label" for="exampleCheck1">Remember me</label>
                                    </div>
                                </form>
                                <div className='mt-4'>
                                    <button className='btn btn-primary fw-medium rounded-1 px-3 py-2' onClick={DashboardScreen}>Proceed to Login <i class="bi bi-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}