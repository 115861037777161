import React from 'react';
import { useLocation } from 'react-router-dom';
import 'react-medium-image-zoom/dist/styles.css';
import ModalImage from "react-modal-image";

const Data = [
    {
        questions: "What does HTML stand for?",
        reason: 'Hyper Text Markup Language',
        status: "Correct",
    },
    {
        questions: "Who is making the Web standards?",
        reason: 'The World Wide Web Consortium',
        status: "Correct",
    },
    {
        questions: "Choose the correct HTML element for the largest heading:",
        reason: '<heading>',
        status: "Incorrect",
    },
    {
        questions: "What is the correct HTML element for inserting a line break?",
        reason: '<br>',
        status: "Correct",
    },
    {
        questions: "What is the correct HTML for adding a background color?",
        reason: ' <background>yellow</background>',
        status: "Incorrect",
    },
    {
        questions: "Choose the correct HTML element to define important text",
        reason: '<i>',
        status: "Correct",
    },
    {
        questions: "What does HTML stand for?",
        reason: 'Hyper Text Markup Language',
        status: "Correct",
    },
    {
        questions: "Who is making the Web standards?",
        reason: 'The World Wide Web Consortium',
        status: "Correct",
    },
    {
        questions: "Choose the correct HTML element for the largest heading:",
        reason: '<heading>',
        status: "Incorrect",
    },
    {
        questions: "What is the correct HTML element for inserting a line break?",
        reason: '<br>',
        status: "Correct",
    },
    {
        questions: "What is the correct HTML for adding a background color?",
        reason: ' <background>yellow</background>',
        status: "Incorrect",
    },
    {
        questions: "Choose the correct HTML element to define important text",
        reason: '<i>',
        status: "Correct",
    },

]

export const TestDetails = () => {
    const location = useLocation()
    const detail = location.state;

    return (
        <div className='cand-test-details py-4 py-xl-5'>
            <div className='container'>

                {/* Candidate Profile Details */}
                <div className='card p-3 p-lg-4'>
                    <div className="candidate-details previous-test-wrapper">
                        <div className="row align-items-center">
                            <div className='col-xxl-2 col-lg-3 col-md-4'>
                                <img src='https://mdbootstrap.com/img/new/avatars/8.jpg' alt="Candidate" className="img-fluid border rounded-2" />
                            </div>

                            <div className="col-xxl-10 col-lg-9 col-md-8 mt-2 mt-md-0">
                                <div className="fs-4 cand-name fw-semibold text-capitalize lh-sm mb-1">john doe</div>
                                <div className="cand-profile text-muted mb-1">UI/UX Designer</div>
                                <div className='d-flex gap-4 gap-md-5 mb-1'>
                                    <div className='candidate-contact d-flex align-items-center gap-2'><i className="bi bi-calendar-check-fill text-primary"></i> {detail.date}</div>
                                    <div className='candidate-contact d-flex align-items-center gap-2'><i className="bi bi-clock-fill text-primary"></i> {detail.time}</div>
                                </div>
                                <div className='d-flex align-items-center gap-2 gap-md-4 mb-1'>
                                    <div className='label fw-semibold'>Correct / Incorrect:</div>
                                    <div className='d-flex align-items-center gap-4'>
                                        <div className='fw-semibold'><i className="bi bi-check-circle-fill text-success"></i> {detail.correct}</div>
                                        <div className='fw-semibold'><i className="bi bi-x-circle-fill text-danger"></i> {detail.inCorrect}</div>
                                    </div>
                                </div>
                                <div className='label fw-bold border-top pt-3 mt-2'>
                                    <button className={`btn px-3 fw-semibold lh-sm ${detail.status === 'Pass' ? 'btn-success' : 'btn-secondary' && detail.status === 'Fail' ? 'btn-danger' : 'btn-secondary'}`} style={{ minWidth: '90px' }}>{detail.status}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='ques-ans-wrapper'>
                    {Data.map((info, index) => (
                        <div className='ques-ans-item card border-1 border-5 border-top border-secondary border-opacity-75 p-3 p-md-4 mt-3' key={index}>
                            <div className='d-flex flex-column flex-md-row gap-md-2 mb-2'>
                                <div className='title-label fw-semibold'>Question {index + 1}:</div>
                                <div className='question'>{info.questions}</div>
                            </div>
                            <div className='d-flex flex-column flex-md-row gap-md-2 mb-3'>
                                <div className='title-label fw-semibold'>Answer:</div>
                                <div className='ans-img mt-2'>
                                    <ModalImage className="img-fluid border border-2 border-secondary rounded-1" showRotate
                                        small={require ('../../assets/code.png')}
                                        large={require ('../../assets/code.png')}
                                        alt="Test Details" style={{ width: '400px', height: '250px', objectFit: 'cover' }}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-md-row gap-md-2 mb-2'>
                                <div className='title-label fw-semibold'>Reason:</div>
                                <div className='question text-secondary'>{info.reason}</div>
                            </div>
                            <div className='d-flex align-items-center gap-2'>
                                <div className='title-label fw-semibold'>Result:</div>
                                <div className={`fw-semibold text-uppercase ${info.status === 'Correct' ? 'text-success' : 'text-danger'}`}>{info.status}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
