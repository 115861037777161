import React, { useState, useEffect, useContext } from 'react';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import moment from 'moment';

import ProfileContext from '../context/ProfileContext';
// Import slick slider files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { languageOptions } from "../constants/languageOptions";
import Select from "react-select";

import briefcase from '../assets/briefcase.svg'
import schoolbook from '../assets/schoolbook.svg'
import whatsapp from '../assets/whatsapp.svg'
import axios from 'axios';

const Signin = () => {

  const { setProfile, profile } = useContext(ProfileContext);

  const [currentCompany, setCurrentCompany] = useState('');
  const [totalExperience, setTotalExperience] = useState(1);
  const [jobStatus, setJobStatus] = useState('');
  const [preferedLang, setPreferedLang] = useState('');
  const [jobStatusErrMsg, setjobStatusErrMsg] = useState("");
  const [currCompErrMsg, setcurrCompErrMsg] = useState("");
  const [progLangErrMsg, setprogLangErrMsg] = useState("");
  const [isInJob, setisInJob] = useState(true);
  const [isSigned, setIssined] = useState(false);
const [radioVal,setRadioVal] = useState("");

  // End: Profile Page Code

  const { googleSignIn, user, isFormSub, googleSignedUser } = UserAuth();
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      
      if(radioVal == "experienced")
      {
        let isValidate = validateUserInput();
        if (isValidate) {
          await googleSignIn();
        }
      }
    else if (radioVal == "fresher" && preferedLang != "")
    {
      await googleSignIn();  
    }
    else
    {
      toast.error('Unable to process your request !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
    
    } catch (error) {
      console.log(error);
    }
  };

  //For Already Signed IN users
  const handleSignIn = async () => {
    try {
      setIssined(true);
      await googleSignedUser();
    } catch (error) {
      console.log(error);
      setIssined(false);
    }
  };

  const validateUserInput = () => {
    let isValidateCheck = true;
    if (jobStatus == "") {
      isValidateCheck = false;
      setjobStatusErrMsg("Please select your current Job status.");
    }
    else
      setjobStatusErrMsg("");
    if (currentCompany == "") {
      isValidateCheck = false;
      setcurrCompErrMsg("Please select your current company.");
    }
    else
      setcurrCompErrMsg("");
    if (preferedLang == "") {
      isValidateCheck = false;
      setprogLangErrMsg("Please select prefered language for coding test.");
    }
    else
      setprogLangErrMsg("");

    return isValidateCheck;

  }

  

const saveData = async () => {
  let mysqlTimestamp = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

  const InterviewerInfo = {
    display_name: user?.displayName,
    email: user?.email,
    contact_no: user?.phoneNumber,
    profile_img: user?.photoURL,
    current_company: currentCompany,
    total_exp: totalExperience,
    prefered_lang: preferedLang?.value,
    job_status: jobStatus,
    login_datetime: mysqlTimestamp,
    login_token: user?.uid,
    lang_id: preferedLang?.id,
    candidate_status: radioVal
  };
  // console.log(InterviewerInfo)
  setProfile(InterviewerInfo);

  // const apiUrl = `${process.env.REACT_APP_nmInterviewFunc}/signin`;
  const apiUrl = process.env.REACT_APP_nmInterviewFunc;
  // const apiUrl = '';
console.log("apiUrl");
// console.log(apiUrl);

  try {
    const refreshedToken = await user?.getIdToken(true);

    const response = await axios.post(apiUrl, InterviewerInfo, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${refreshedToken}`
      }
    });

    // console.log(response.data);
  } catch (error) {
    console.error('Error:', error);
  }
};

  
  
  useEffect(() => {
    if (user?.displayName) {
      if (isFormSub) {
        saveData();
        navigate('/account');
      }
      else if (isSigned) {
        fetchUserInfo(user);
      }
      else {
        navigate('/account');
      }
    }
  }, [user, isFormSub, isSigned]);

  const fetchUserInfo = async (user) => {
    try {
      let mysqlTimestamp = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
      const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json',
      };
  
      const response = await axios.post(process.env.REACT_APP_APIURL, {
        operation: 'fetchuser',
        email: user?.email,
      }, {
        headers: headers,
      });
  
      const dataVal = response.data;
      if (dataVal.length === 0) {
        toast.error('Please sign up we do not have your account !', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      } else {
        const userData = dataVal[0];
        const InterviewerInfo = {
          display_name: user?.displayName,
          email: user?.email,
          contact_no: user?.phoneNumber,
          profile_img: user?.photoURL,
          current_company: userData?.current_company,
          total_exp: parseInt(userData?.total_exp),
          prefered_lang: userData?.prefered_lang,
          job_status: userData?.job_status,
          login_datetime: mysqlTimestamp,
          login_token: user?.uid
        };
        setProfile(InterviewerInfo);
        navigate('/account');
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  

  var settings = {
    arrows: false,
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const langArray = ["JavaScript", "Python", "Java", "C++", "PHP", "Ruby", "Swift", "Objective-C", "Go", "Kotlin", "C#", "Rust", "Scala", "Perl", "TypeScript", "SQL"];

  
  return (
    <div className='authentication-page d-flex align-items-center py-3'>
      <div className='container'>
        <div className='auth_page bg-white rounded-4 m-auto pe-md-0'>
          <div className='row g-0'>
            <div className='col-lg-5'>
              <div className='left-section d-flex flex-column bg-primary text-white rounded-3 h-100'>
                <div className='logo-title text-uppercase small fw-bold mb-5'>
                  <img className='img-fluid rounded-3' src={require('../assets/virtual-logo.png')} alt='Logo' />
                </div>
                <div className='heading-title fs-2 fw-bold mb-3'>Start Your<br /> Journey With Us.</div>
                <div className='heading-subtitle'>Connect with the leading alliance of tech specialists and entrepreneurs, shaping the future of IT services.</div>

                <div className='slider-wrapper pt-5 mt-auto'>
                  <Slider {...settings}>
                    <div className='testimonial-cards rounded-4 p-3'>
                      <p className='description'>Experience a live interview and seize the chance to secure a job offer.</p>
                      <div className='d-flex align-items-center gap-2'>
                        {/*<div className='img-chip'>
                          <img className='img-fluid rounded-2 border border-light' src={require('../assets/Avatar.png')} alt='User' />
                        </div>
                        <div className='chip-details'>
                          <div className='user-name fw-bold'>VirtualEmployee</div>
                          <div className='user-profile'></div>
                        </div> */}
                      </div>
                    </div>
                    <div className='testimonial-cards rounded-4 p-3'>
                      <p className='description'>Grab your chance to earn the best salaries the market has to offer.</p>
                      <div className='d-flex align-items-center gap-2'>
                        {/*<div className='img-chip'>
                          <img className='img-fluid rounded-2 border border-light' src={require('../assets/Avatar.png')} alt='User' />
                        </div>
                        <div className='chip-details'>
                          <div className='user-name fw-bold'>VirtualEmployee</div>
                          <div className='user-profile'></div>
                        </div> */}
                      </div>
                    </div>
                    <div className='testimonial-cards rounded-4 p-3'>
                      <p className='description'>Enhance and evaluate your skills using the premier self-assessment tool in the industry.</p>
                      <div className='d-flex align-items-center gap-2'>
                        {/* <div className='img-chip'>
                          <img className='img-fluid rounded-2 border border-light' src={require('../assets/Avatar.png')} alt='User' />
                        </div> 
                         <div className='chip-details'>
                          <div className='user-name fw-bold'>VirtualEmployee</div>
                          <div className='user-profile'></div>
                        </div> */}
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='right-section h-100 py-4 px-1 px-md-4 px-lg-5 p-xxl-5'>
                <h5 className='fs-5 fw-bold'>Sign Up</h5>
                <div className='subtitle small'>Have an account? <Link className='btn-link ms-1' onClick={handleSignIn}>Sign In</Link></div>
                <form className='mt-2'>
                  <div className="form-group userType mb-3">
                    <label className="form-label">Work status</label>
                    <div className="work-status-wrapper d-flex flex-column flex-sm-row gap-2 gap-xxl-3">
                      <div className="optionWrap">
                        <input type="radio" 
                        className="btn-check" 
                        name="options" 
                        id="option1" 
                        value="experienced" 
                        autoComplete="off" 
                        onChange={(event)=>{setRadioVal(event.target.value)}}
                        required />
                        <label htmlFor="option1" className="work-details d-flex align-items-center justify-content-between gap-2 border rounded p-3">
                          <div className="textWrap">
                            <h2 className="title fs-6 fw-medium mb-1">I'm experienced</h2>
                            <p className="subtitle small text-secondary m-0">I have work experience (excluding internships)</p>
                          </div>
                          <div className="iconWrap">
                            <img src={briefcase} alt='briefcase' className='img-fluid' />
                          </div>
                        </label>
                      </div>
                      <div className="optionWrap">
                        <input type="radio" className="btn-check" 
                        name="options" 
                        id="option2"
                        value="fresher" 
                        onChange={(event)=>{setRadioVal(event.target.value)}}
                        autoComplete="off" />
                        <label htmlFor="option2" className="work-details d-flex align-items-center justify-content-between gap-2 border rounded p-3">
                          <div className="textWrap">
                            <h2 className="title fs-6 fw-medium mb-1">I'm a fresher</h2>
                            <p className="subtitle small text-secondary m-0">I am a student/ Haven't worked after graduation </p>
                          </div>
                          <div className="iconWrap">
                            <img src={schoolbook} alt='Schoolbook' className='img-fluid' />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {radioVal != "fresher" ? <div><div className="user-status-wrapper mb-3">
                    <label className="form-label">Are you ?</label>
                    <div className="check-wrapper d-flex flex-column flex-xxl-row flex-xxl-wrap gap-2 gap-xxl-3">
                      <div className="form-check form-check-inline btn-with-radio m-0">
                        <input className="form-check-input ms-0 me-2" type="radio" name="inlineRadioOptions"
                          id="inlineRadio1" value="Currently On Notice Period"
                          onChange={(event) => {
                            setisInJob(true);
                            setjobStatusErrMsg("");
                            setJobStatus(event.currentTarget.value)
                          }}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">Currently On Notice Period</label>
                      </div>
                      <div className="form-check form-check-inline btn-with-radio m-0">
                        <input className="form-check-input ms-0 me-2" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="No Where Working"
                          onChange={(event) => {
                            setisInJob(false);
                            setjobStatusErrMsg("");
                            setJobStatus(event.currentTarget.value)
                          }}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">No Where Working</label>
                      </div>
                      <div className="form-check form-check-inline btn-with-radio m-0">
                        <input className="form-check-input ms-0 me-2" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="In Job"
                          onChange={(event) => {
                            setisInJob(true);
                            setjobStatusErrMsg("");
                            setJobStatus(event.currentTarget.value)
                          }}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio3">In Job</label>
                      </div>
                    </div>
                    <span className="small text-danger">{jobStatusErrMsg != "" ? jobStatusErrMsg : ""}</span>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">{isInJob ? "Current Company" : "Last Company"}</label>
                    <input type="text" className='form-control' placeholder='Enter company name' value={currentCompany} onChange={(e) => {
                      setcurrCompErrMsg("");
                      setCurrentCompany(e.target.value)
                    }} />
                    <span className="small text-danger">{currCompErrMsg != "" ? currCompErrMsg : ""}</span>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Total Experience</label>
                    <select className='form-select' value={totalExperience} onChange={(e) => setTotalExperience(Number(e.target.value))}>
                      {[...Array(21)].map((_, i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div></div>:null}
                  
                  <div className="user-status-wrapper mb-3">
                    <label className="form-label">Select your prefered programing language</label>
                    <Select className='custom-select' classNamePrefix='custom-select'
                      placeholder="Select Language"
                      options={languageOptions}
                      // defaultValue={languageOptions[0]}
                      onChange={(selectedOption) => {
                        setPreferedLang(selectedOption);
                      }}
                    />
                    <span className="small text-danger">{progLangErrMsg != "" ? progLangErrMsg : ""}</span>
                  </div>
                  {/* <div className="form-check mb-3 pt-1">
                    <input type="checkbox" className="form-check-input" id="whatsappCheck" required />
                    <label className="form-check-label small fw-medium" for="whatsappCheck">Send me important updates on <img src={whatsapp} alt='Whatsapp Icon' className="img-fluid" /><span className="whatsappText">WhatsApp</span></label>
                  </div> */}
                  <p className="small text-secondary">By clicking Sign in, you agree to the<Link to="#" target="_blank" className="btn-link text-decoration-none"> Terms and Conditions </Link> & <Link to="#" target="_blank" className="btn-link text-decoration-none"> Privacy Policy </Link> of Virtualemployee.com</p>
                </form>
                <div className='mt-4'>
                  <GoogleButton onClick={handleGoogleSignIn} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signin;