import React, { useState, useEffect, useContext } from 'react';
import Select from "react-select";
import searchIcon from '../assets/search.svg';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skills from './Skills';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

const SkillsVal = Skills.map((skill, index) => ({
    id: index + 1,
    name: skill,
    value: skill,
    label: skill
}));



const Qualification = [

    {
        id: 1,
        name: "Masters",
        label: "Masters",
        value: "Masters",
    },
    {
        id: 2,
        name: "Graduation",
        label: "Graduation",
        value: "Graduation",
    },
    {
        id: 3,
        name: "Diploma",
        label: "Diploma",
        value: "Diploma",
    },
    {
        id: 4,
        name: "Intermediate (12th)",
        label: "Intermediate (12th)",
        value: "Intermediate (12th)",
    },
    {
        id: 5,
        name: "Highschool (10th)",
        label: "Highschool (10th)",
        value: "Highschool (10th)",
    },
]

const Course = [
    {
        id: 1,
        name: "B.S",
        label: "B.S",
        value: "B.S",
    },
    {
        id: 2,
        name: "B.E",
        label: "B.E",
        value: "B.E",
    },

    {
        id: 3,
        name: "B.Tech",
        label: "B.Tech",
        value: "B.Tech",
    },
    {
        id: 4,
        name: "B.IT",
        label: "B.IT",
        value: "B.IT",
    },
    {
        id: 5,
        name: "M.Eng",
        label: "M.Eng",
        value: "M.Eng",
    },
    {
        id: 6,
        name: "MCA",
        label: "MCA",
        value: "MCA",
    },
    {
        id: 7,
        name: "BCA",
        label: "BCA",
        value: "BCA",
    },
    {
        id: 8,
        name: "Ph.D",
        label: "Ph.D",
        value: "Ph.D",
    },
    {
        id: 9,
        name: "A.A.S",
        label: "A.A.S",
        value: "A.A.S",
    },
]

const Specialization = [
    {
        id: 1,
        name: "Computers",
        label: "Computers",
        value: "Computers",
    },
    {
        id: 2,
        name: "Finance",
        label: "Finance",
        value: "Finance",
    },
    {
        id: 3,
        name: "IT Networks",
        label: "IT Networks",
        value: "IT Networks",
    },
    {
        id: 4,
        name: "Advertising Management",
        label: "Advertising Management",
        value: "Advertising Management",
    },
    {
        id: 5,
        name: "Mass Communication",
        label: "Mass Communication",
        value: "Mass Communication",
    },
    {
        id: 6,
        name: "Media Management",
        label: "Media Management",
        value: "Media Management",
    },
]

export const CompleteProfile = () => {
    const { user, logOut } = UserAuth();
    const navigate = useNavigate();
    const [address, setAddress] = useState('');
    const [jobtitle, setJobTitle] = useState('');
    const [city, setCity] = useState('');
    const [annualsal, setAnnualsal] = useState('');
    const [state, setState] = useState('');
    const [selectedDateFrom, setSelectedDateFrom] = useState('');
    const [selectedDateTo, setSelectedDateTo] = useState('');
    const [noticePeriod, setNoticePeriod] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [qualification, setQualification] = useState('');
    const [isQualificationSelected, setIsQualificationSelected] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [isCourseSelected, setIsCourseSelected] = useState(false);
    const [selectedSpecializations, setSelectedSpecializations] = useState([]);
    const [areSpecializationsSelected, setAreSpecializationsSelected] = useState(false);
    const [university, setUniversity] = useState('');
    const [isUniversityValid, setIsUniversityValid] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [courseVal, setCourse] = useState('');
    const [isCourseValid, setIsValidCourse] = useState(false);
    const [numberValue, setNumberValue] = useState('');
    const [passingYear, setPassingYear] = useState('');
    const [isValidPassYear, setIsValidPassYear] = useState(true);
    const [isform2Submitted, setForm2Submit] = useState(false);
    const [resumeHeadline, setResumeHeadline] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [isVerified, setVerification] = useState(false);
    const [isOtherVerified, setOtherVerified] = useState(false);
    const [isEducationVerified, setEducationVerified] = useState(false);
    const [isHeadlineVerified, setHeadlineVerified] = useState(false);
    const [isFresher, setIsFresher] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [dateError, setDateError] = useState('');
    useEffect(() => {
        if(user?.accessToken)
        fetchUserInfo();
      }, [user])
     //Fetch UserInfo
     const fetchUserInfo = async () => {
        try {
          const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
          const headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json',
          };
      
          const response = await axios.post(process.env.REACT_APP_APIURL, {
            operation: 'fetchuser',
            email: user?.email,
          }, {
            headers: headers,
          });
      
          const dataVal = response.data[0];
          setVerification(dataVal?.is_video_verified === 1);
          setOtherVerified(dataVal?.is_employement_added === 1);
          setEducationVerified(dataVal?.is_edu_added === 1);
          setHeadlineVerified(dataVal?.resume_headline_added === 1);
          setIsFresher(dataVal?.candidate_status === 'fresher');
      
          const isStep1Verified = dataVal?.is_employement_added === 1;
          const isStep2Verified = dataVal?.is_edu_added === 1;
          const isStep3Verified = dataVal?.resume_headline_added === 1;
          const isFresh = dataVal?.candidate_status === 'fresher';
      
          const step1 = document.getElementById('step1');
          const step2 = document.getElementById('step2');
          const step3 = document.getElementById('step3');
          const step5 = document.getElementById('step5');
      
          if (!isStep1Verified) {
            if (isFresh) {
              step5.style.display = 'block';
              step1.style.display = 'none';
            } else {
              step1.style.display = 'block';
              step5.style.display = 'none';
            }
            step2.style.display = 'none';
            step3.style.display = 'none';
          } else if (isStep1Verified && !isStep2Verified) {
            step1.style.display = 'none';
            step2.style.display = 'block';
            step3.style.display = 'none';
          } else if (isStep1Verified && isStep2Verified && !isStep3Verified) {
            step1.style.display = 'none';
            step2.style.display = 'none';
            step3.style.display = 'block';
          } else {
            navigate('/account');
          }
        } catch (error) {
          console.error(error);
        }
      };
    const handleTextareaChange = (event) => {
        const value = event.target.value;
        setResumeHeadline(value);
        setValidationError(value.trim() === '' ? 'Resume headline is required' : '');
      };
    
      const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if(event.target.checked)
        {setValidationError("");
            setResumeHeadline("Web Designer with BCA in Computers currently living in New Delhi");}
        else
        setResumeHeadline("");
    };
      

    const currentYear = new Date().getFullYear();
    const fiveYearsBefore = [];
    const fiveYearLater = [];

    for (let i = 0; i < 5; i++) {
        fiveYearsBefore.push(currentYear - i);
    }
    for (let i = 0; i < 5; i++) {
        fiveYearLater.push(currentYear + i);
    }
    const handleNumberInputChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= 4) { // Change 5 to your desired maximum digits
            setNumberValue(inputValue);
        }
    };

    const handlePassYearchange = (event) => {
        const passYear = event.target.value;
        if (passYear.length <= 4) {
            setPassingYear(passYear);
        }
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        setIsValid(value.trim() !== ''); // Validate if input is not empty
    };
    const handleUniversityChange = (event) => {
        const inputValue = event.target.value;
        setUniversity(inputValue);
        setIsUniversityValid(inputValue.trim() !== ''); // Validate if input is not empty
    };
    const handleCourseChange = (selectedOptions) => {
        setSelectedCourses(selectedOptions);
        setIsCourseSelected(selectedOptions.length > 0);
    };

    const handleSpecializationChange = (selectedOptions) => {
        setSelectedSpecializations(selectedOptions);
        setAreSpecializationsSelected(selectedOptions.length > 0);
    };

    const handleValidatespec = () => {
        setAreSpecializationsSelected(selectedSpecializations.length > 0);
    };
    const handleValidationClick = () => {
        setIsCourseSelected(selectedCourses.length > 0);
    };
    const qualifications = [
        "Doctorate/PhD",
        "Masters/Post-Graduation",
        "Graduation",
        "Diploma",
        "Intermediate (12th)",
        "Highschool (10th)"
    ];
    const courseType = ["Full Time", "Part Time", "Distance Learning"];

    const [errors, setErrors] = useState({
        jobtitle: '',
        city: '',
        working: '',
        annualsal: '',
        skills: '',
        notice: ''
    });

    const citySuggestion = ["Mumbai", "Chennai", "New Delhi", "Pune", "Delhi NCR", "Bangalore/Bengaluru"];
    const noticeArray = ["15 Days or less", "1 Month", "2 Month", "3 Month", "More than 3 Month"];
    // Get the current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().split('T')[0];

    const handleQualificationChange = (event) => {
        const selectedQualification = event.target.value;
        setQualification(selectedQualification);
        setIsQualificationSelected(selectedQualification !== '');
    };

    const validateUniversity = () => {
        if (university.trim() !== '') {
            // Perform form submission or further processing here
            setIsUniversityValid(true);
        } else {
            setIsUniversityValid(false); // Show validation error if university is empty
        }
    };

    const validateJobTitle = () => {
        const isValid = jobtitle.trim() !== '';
        setErrors((prevErrors) => ({
            ...prevErrors,
            jobtitle: isValid ? '' : 'Please enter a valid job Title.',
        }));
        return isValid;
    };
    const validateCity = () => {
        const isValid = city.trim() !== '';
        setErrors((prevErrors) => ({
            ...prevErrors,
            city: isValid ? '' : 'Please enter City.',
        }));
        return isValid;
    };

    const validateAnnualsal = () => {
        const isValid = annualsal.trim() !== '';
        setErrors((prevErrors) => ({
            ...prevErrors,
            annualsal: isValid ? '' : 'Please enter your Salary.',
        }));
        return isValid;
    };
    const validateNotice = () => {
        const isValid = noticePeriod.trim() !== '';
        setErrors((prevErrors) => ({
            ...prevErrors,
            notice: isValid ? '' : 'Please select notice period.',
        }));
        return isValid;
    };
    const validateSkill = () => {
        const isValid = selectedOption?.length != 0;
        setErrors((prevErrors) => ({
            ...prevErrors,
            skills: isValid ? '' : 'Please select your skills.',
        }));
        return isValid;
    };

    const validateQfy = () => {
        setIsQualificationSelected(qualification !== '');
        return (qualification !== '');
    }

    const validateCourse = () => {
        const isValid = selectedCourses?.length != 0;
        setIsCourseSelected(isValid);
        return isValid;
    };
  
    const validateDates = (from, to) => {
        if (from && to) {
          const fromDateObj = new Date(from);
          const toDateObj = new Date(to);
          
          if (fromDateObj > toDateObj) {
            // console.log("From date cannot be after to date.");
            setDateError('From date cannot be after to date.');
          } else {
            setDateError('');
          }
        }
      };

    //Add Employment
    const submitStep1 = () => {

        const step1 = document.getElementById('step1');
        const step2 = document.getElementById('step2');
        const step3 = document.getElementById('step3');
        let stepperVal2 = document.getElementById("stepNav2");
        let stepperVal3 = document.getElementById("stepNav3");
        
        
        step3.style.display = 'none';

        const jobtitleValid = validateJobTitle();
        const cityValid = validateCity();
        const annualsalvalid = validateAnnualsal();
        const validateNoticeVal = validateNotice();
        const validateSkills = validateSkill();
        let dateValidate = true;
        const fromDateObj = new Date(selectedDateFrom);
        const toDateObj = new Date(selectedDateTo);
        if (fromDateObj > toDateObj) {
          setDateError('From date cannot be after to date.');
          dateValidate = false;
        } else if(selectedDateFrom == "" || selectedDateTo == "") {
          setDateError('Working since and To date can not be left blank.');
          dateValidate = false;
        }
        else
        {
            setDateError('');
            dateValidate = true; 
        }
        if (jobtitleValid && cityValid && annualsalvalid && validateNoticeVal && validateSkills && dateValidate) {

            const commaSeparatedNames = selectedOption.map(obj => obj.value).join(', ');

            const employmentData =
            {
                "user_email": user?.email,
                "jobtitle": jobtitle,
                "city": city,
                "workingfrom": selectedDateFrom,
                "workingto": selectedDateTo,
                "annualsal": annualsal,
                "noticeperiod": noticePeriod,
                "skills": commaSeparatedNames,
                "login_token": user?.uid,
                "operation": "updateEmployement"
            }
            // URL and data for the POST request
            const url = 'https://dw7z4gol6g.execute-api.us-east-1.amazonaws.com/prodstage/updateinfo';
            setLoading(true);
            // Making the POST request
            axios.post(url, employmentData)
                .then(response => {
                    step1.style.display = 'none';
                    step2.style.display = 'block';
                    setLoading(false);
                    stepperVal3.classList.add('active');
                    stepperVal2.classList.remove('active');
                    stepperVal2.classList.add('completed');
                })
                .catch(error => {
                    console.error('Error creating post:', error);
                    setLoading(false);
                });
          
        }
    }

    const submitStep2 = () => {
        setForm2Submit(true);
        const step1 = document.getElementById('step1');
        const step2 = document.getElementById('step2');
        const step3 = document.getElementById('step3');

        let stepperVal3 = document.getElementById("stepNav3");
        let stepperVal4 = document.getElementById("stepNav4");
        step1.style.display = 'none';
        const validateQualification = validateQfy();
        const validcourse = validateCourse();
        if(validateQualification && validcourse && selectedSpecializations?.length > 0 && selectedCourses?.length > 0)
        {
            const courseData = selectedCourses?.length > 0 ? selectedCourses.map(obj => obj.value).join(', '): "";
            const specializationval = selectedSpecializations?.length > 0 ? selectedSpecializations.map(obj => obj.value).join(', '): "";
            setLoading(true);
            const educationData ={
                higest_qualification: qualification,
                course: courseData,
                specialization: specializationval,
                university: university,
                course_type: courseVal,
                start_year: numberValue,
                pass_year: passingYear,
                "login_token": user?.uid,
                "operation": "updateEducation", 
                "user_email": user?.email,
            }
            
            const url = 'https://dw7z4gol6g.execute-api.us-east-1.amazonaws.com/prodstage/updateinfo';
            axios.post(url, educationData)
                .then(response => {
                    step1.style.display = 'none';
                    step2.style.display = 'none';
                    step3.style.display = 'block';
                    setLoading(false);
                    stepperVal4.classList.add('active');
                    stepperVal3.classList.remove('active');
                    stepperVal3.classList.add('completed');
                })
                .catch(error => {
                    console.error('Error creating post:', error);
                    setLoading(false);
                });
       
        }
    }

    //Submit for Resume Heaadline
    const submitStep3 = ()=>{
        const step1 = document.getElementById('step1');
        const step2 = document.getElementById('step2');
        const step3 = document.getElementById('step3');   
        step1.style.display = 'none';
        step2.style.display = 'none';
        if(resumeHeadline.trim() === "")
        setValidationError("Please enter resume headline.");
        else
        {
          const resumeData = {
            "login_token": user?.uid,
            "operation": "updateResumeData", 
            "user_email": user?.email,
            "resume_heading": resumeHeadline
          } 
          setLoading(true); 
          const url = 'https://dw7z4gol6g.execute-api.us-east-1.amazonaws.com/prodstage/updateinfo';
          axios.post(url, resumeData)
              .then(response => {
                  step1.style.display = 'none';
                  step2.style.display = 'none';
                  step3.style.display = 'block';
                  setLoading(false);
                  toast.success('Data Saved !', {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                  navigate('/account');
              })
              .catch(error => {
                  console.error('Error creating post:', error);
                  setLoading(false);
              });
        
        
        }
    } 

    const submitStep5 = () => {

        const step1 = document.getElementById('step1');
        const step2 = document.getElementById('step2');
        const step3 = document.getElementById('step3');
        const step5 = document.getElementById('step5');
        step1.style.display = 'none';
        step2.style.display = 'none';
        step3.style.display = 'none';

        const cityValid = validateCity();
        const validateSkills = validateSkill();
        if (cityValid && validateSkills) {
            setLoading(true);
        const commaSeparatedNames = selectedOption.map(obj => obj.value).join(', ');

            const employmentData =
            {
                "user_email": user?.email,
                "jobtitle": jobtitle,
                "city": city,
                "workingfrom": selectedDateFrom,
                "workingto": selectedDateTo,
                "annualsal": annualsal,
                "noticeperiod": noticePeriod,
                "skills": commaSeparatedNames,
                "login_token": user?.uid,
                "operation": "updateEmployement"
            }
            // URL and data for the POST request
            const url = 'https://dw7z4gol6g.execute-api.us-east-1.amazonaws.com/prodstage/updateinfo';

            // Making the POST request
            axios.post(url, employmentData)
                .then(response => {
                    step1.style.display = 'none';
                    step5.style.display = 'none';
                    step2.style.display = 'block';
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error creating post:', error);
                    setLoading(false);
                });
          
        }
    }

    const handleChange = (newAddress) => {
        setAddress(newAddress);
        setErrors((prevErrors) => ({
            ...prevErrors,
            city: '',
        }));
    };

    const handleNumberChange = (event) => {
        setAnnualsal(event.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            annualsal: '',
        }));
    };

    const handleDateChangeFrom = (event) => {
        setSelectedDateFrom(event.target.value);
        validateDates(event.target.value, selectedDateTo);
    };
    const handleDateChangeTo = (event) => {
        setSelectedDateTo(event.target.value);
        validateDates(selectedDateFrom,event.target.value);
    };

    const handleSelect = async (selectedAddress) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            city: '',
        }));
        setAddress(selectedAddress);

        try {
            const geocodeResults = await geocodeByAddress(selectedAddress);
            const { formatted_address, address_components } = geocodeResults[0];

            // Extract city and state from the address_components
            const cityObj = address_components.find(
                (component) => component.types.includes('locality')
            );
            const stateObj = address_components.find(
                (component) => component.types.includes('administrative_area_level_1')
            );

            // Set city and state in the state
            setCity(cityObj ? cityObj.long_name : '');
            setState(stateObj ? stateObj.long_name : '');
        } catch (error) {
            console.error('Error while geocoding:', error);
        }
    };

    const renderSuggestion = ({ description }) => {
        // Extract city and state from the description
        const cityRegex = /([^,]+),\s([^,]+)/;
        const matches = description.match(cityRegex);
        const cityName = matches ? matches[1] : '';
        const state = matches ? matches[2] : '';

        return (
            <div>
                {cityName && <strong>{cityName}</strong>}
                {state && (
                    <>
                        {cityName && ', '}
                        <span>{state}</span>
                    </>
                )}
            </div>
        );
    };
    return (
        <div className='complete-profile'>
            <div className='container'>
                {/* Stepper Navigation */}
                <div className='stepbar-wrapper mt-5 mx-auto' style={{ maxWidth: '600px' }}>
                    <ul id="stepBar" className='stepper-nav d-flex align-items-center justify-content-between mb-4 mb-md-5 pb-3'>
                        <li className="completed text-center" id="stepNav1">
                            <div className='step-count d-flex align-items-center justify-content-center'></div>
                            <div className='step-name'>Profile verify</div>
                        </li>
                        <li className="active text-center" id="stepNav2">
                            <div className='step-count d-flex align-items-center justify-content-center'></div>
                            <div className='step-name'>Employment</div>
                        </li>
                        <li className="text-center" id="stepNav3">
                            <div className='step-count d-flex align-items-center justify-content-center'></div>
                            <div className='step-name'>Education</div>
                        </li>
                        <li className="text-center" id="stepNav4">
                            <div className='step-count d-flex align-items-center justify-content-center'></div>
                            <div className='step-name'>Last step</div>
                        </li>
                    </ul>
                </div>

                {/* Profile Step forms */}
                <div className='row gx-xl-5'>
                    <div className='col-lg-4 left-sec order-1 mt-4 mt-lg-0'>
                        <div className="card">
                            <div className='text-center'>
                                <img src={require('../assets/registration-boy.png')} alt='Registration' className='img-fluid' style={{ width: '144px', height: '144px' }} />
                                <h2 className="fs-18 fw-bold text-center mt-3">On registering, you can</h2>
                            </div>

                            <div className='d-flex flex-column gap-3 regis-info-list mt-1 mt-xxl-3 mx-auto' style={{ maxWidth: '330px' }}>
                                <div className='list-item d-flex gap-2'>
                                    <i className="bi bi-check-circle-fill"></i>
                                    <p className="small fw-medium text-muted m-0">Build your profile and let recruiters find you</p>
                                </div>
                                <div className='list-item d-flex gap-2'>
                                    <i className="bi bi-check-circle-fill"></i>
                                    <p className="small fw-medium text-muted m-0">Get job postings delivered right to your email</p>
                                </div>
                                <div className='list-item d-flex gap-2'>
                                    <i className="bi bi-check-circle-fill"></i>
                                    <p className="small fw-medium text-muted m-0">Find a job and grow your career</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-8 right-sec order-lg-2'>
                        <div className='card px-3 py-4 p-md-5 shadow border-0 rounded-2 mx-auto'>
                            <div>
                                <div id="step1" className='steps'>
                                    <div className='title-header mb-4'>
                                        <div className='fs-5 fw-semibold'>Add your employment</div>
                                        <div className='small text-secondary'>Employment details help recruiters to understand your background</div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Job title</label>
                                        <input type="text"
                                            className={`form-control ${errors.jobtitle != '' ? 'border border-danger' : ''}`}
                                            placeholder='Eg. Software Developer'
                                            value={jobtitle}
                                            onChange={(event) => {
                                                setJobTitle(event.target.value)
                                                setErrors((prevErrors) => ({
                                                    ...prevErrors,
                                                    jobtitle: '',
                                                }));
                                            }}
                                        />
                                        {errors?.jobtitle ? <div class="small text-danger">{errors.jobtitle}</div> : null}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Current city</label>
                                        {/* <input type="text" className='form-control' placeholder='Mention the city you live in' required /> */}
                                        <div>
                                            {city != "" ? <label className="bg-white small text-nowrap border rounded-pill px-3 py-2">{city}
                                                <span className='ms-2 pointer' type="button" onClick={() => {
                                                    setCity("");
                                                    setAddress("");
                                                    setErrors((prevErrors) => ({
                                                        ...prevErrors,
                                                        city: '',
                                                    }));
                                                }
                                                }><i class="bi bi-x-lg"></i></span>
                                            </label> :
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={handleChange}
                                                    onSelect={handleSelect}
                                                    searchOptions={{ types: ['(cities)'] }} // Filter to get only city names
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search Cities...',
                                                                })}
                                                                className='form-control'
                                                            />
                                                            <div>
                                                                {suggestions.map((suggestion) => (
                                                                    <div
                                                                        key={suggestion.id}
                                                                        {...getSuggestionItemProps(suggestion)}
                                                                    >
                                                                        {renderSuggestion(suggestion)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            }
                                            {errors?.city ? <div class="small text-danger">{errors?.city}</div> : null}
                                        </div>
                                        <div className='suggestion-wrapper mt-2 pb-2'>
                                            <div className='suggestion-label small text-secondary mb-2'>Suggestion:</div>
                                            <div className='d-flex flex-wrap gap-2 text-capitalize'>
                                                {citySuggestion?.map((cityVal) => {
                                                    return (<label className="bg-white small text-nowrap border rounded-pill px-3 py-2"
                                                        onClick={() => {
                                                            setCity(cityVal)
                                                            setAddress("");
                                                            setErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                city: '',
                                                            }));
                                                        }}>{cityVal}</label>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <div className="form-group flex-fill mb-3">
                                            <label className="form-label small">Working since</label>
                                            <input type="date" className='form-control' placeholder='Enter your city'
                                                value={selectedDateFrom}
                                                onChange={handleDateChangeFrom}
                                                max={currentDate}
                                                required />
                                        </div>
                                        <div className='small fw-semibold text-black mt-3'>To</div>
                                        <div className="form-group flex-fill mb-3">
                                            <label className="form-label small opacity-0">Working since</label>
                                            <input type="date" className='form-control'
                                                value={selectedDateTo}
                                                onChange={handleDateChangeTo}
                                                placeholder='Enter your city' required
                                                max={currentDate}
                                            />
                                            {/* <input type="text" className='form-control'  value={'Present'} readOnly disabled /> */}
                                        </div>
                                       
                                    </div>
                                    {dateError && <div className="small text-danger">{dateError}</div>}
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Annual salary</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="package">₹</span>
                                            <input type="number" className="form-control"
                                                value={annualsal}
                                                onChange={handleNumberChange}
                                                placeholder="Eg. 650000" min="100000" max="5000000"
                                                maxLength={11} required />
                                        </div>
                                        {errors?.annualsal ? <div class="small text-danger">{errors?.annualsal}</div> : null}
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label small">Notice period</label>


                                        <div className='radio-wrapper d-flex flex-wrap gap-2'>
                                            {
                                                noticePeriod != "" ?
                                                    <label className="bg-white small text-nowrap border rounded-pill px-3 py-2">{noticePeriod}
                                                        <span className='ms-2 pointer' type="button" onClick={() => {
                                                            setNoticePeriod("")
                                                            setErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                notice: '',
                                                            }));
                                                        }}><i class="bi bi-x-lg"></i></span>
                                                    </label> :
                                                    noticeArray?.map((noticeVal) => {
                                                        return (<div className="radio-item text-nowrap">
                                                            <label className="btn bg-white small border rounded-pill px-3 py-2"
                                                                onClick={() => {
                                                                    setNoticePeriod(noticeVal)
                                                                    setErrors((prevErrors) => ({
                                                                        ...prevErrors,
                                                                        notice: '',
                                                                    }));
                                                                }}
                                                            >{noticeVal}</label>
                                                        </div>)
                                                    })}

                                        </div>
                                        {errors?.notice ? <div class="small text-danger">{errors?.notice}</div> : null}


                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Key skills</label>
                                        <CreatableSelect isMulti className='custom-select' classNamePrefix='custom-select' required
                                            closeMenuOnSelect={false}
                                            name="skills"
                                            options={SkillsVal}
                                            onChange={(value) => {
                                                setSelectedOption(value);
                                                setErrors((prevErrors) => ({
                                                    ...prevErrors,
                                                    skills: '',
                                                }));
                                            }
                                            }
                                            placeholder="Add your skills"
                                        />
                                        <div id="help" className="form-text">Keyskills are crucial for recruiters to hire you</div>
                                        <div className='suggestion-wrapper mt-2 pb-2'>
                                            {errors?.skills ? <div class="small text-danger">{errors?.skills}</div> : null}

                                            <div className='suggestion-label small text-secondary mb-2'>Suggestion:</div>
                                            <div className='d-flex flex-wrap gap-2'>
                                                <label className="bg-white small text-nowrap border rounded-pill px-3 py-2">HTML</label>
                                                <label className="bg-white small text-nowrap border rounded-pill px-3 py-2">CSS </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='btn-wrapper mt-4'>
                                        <button type='button' onClick={() => submitStep1()} className='btn btn-primary btn-custom px-4'>Save and continue</button>
                                    </div>
                                </div>

                                {/* Step 2: Education section style={{ display: 'none' }}*/}
                                <div id="step2" className='steps' style={{ display: 'none' }}>
                                    <div className='title-header mb-4'>
                                        <div className='fs-5 fw-semibold'>Mention your education</div>
                                        <div className='small text-secondary'>Adding your educational details will help recruiters know your value as potential candidate</div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Highest qualification</label>
                                        <select
                                            name="qualification"
                                            id="qualification"
                                            className={`form-select `}
                                            value={qualification}
                                            onChange={handleQualificationChange}
                                            required
                                        >
                                            <option value="" disabled>Choose your qualification</option>
                                            {qualifications.map((qual, index) => (
                                                <option key={index} value={qual}>{qual}</option>
                                            ))}
                                        </select>
                                        <div className={`${isQualificationSelected ? 'small text-success' : 'small text-danger'}`}>
                                            {isform2Submitted && !isQualificationSelected ? 'Please select a qualification.' : ''}
                                        </div>
                                    </div>


                                    <div className="form-group mb-3">
                                        <label className="form-label small">Course</label>
                                        <CreatableSelect
                                            className='custom-select'
                                            classNamePrefix='custom-select'
                                            required
                                            closeMenuOnSelect={false}
                                            isMulti
                                            name="course"
                                            options={Course}
                                            value={selectedCourses}
                                            onChange={handleCourseChange}
                                            placeholder="Add your course"
                                        />
                                        <div className={`${isCourseSelected ? 'small text-success' : 'small text-danger'}`}>
                                            {isform2Submitted && !isCourseSelected ? 'Please select at least one course.' : ''}
                                        </div>

                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Specialization</label>
                                        <CreatableSelect className='custom-select' classNamePrefix='custom-select' required
                                            closeMenuOnSelect={false}
                                            // defaultValue={Skills[0]}
                                            isMulti
                                            name="specialization"
                                            options={Specialization}
                                            placeholder="Mention your specialization"
                                            value={selectedSpecializations}
                                            onChange={handleSpecializationChange}
                                        />
                                        <div className={`${areSpecializationsSelected ? 'small text-success' : 'small text-danger'}`}>
                                            {isform2Submitted && !areSpecializationsSelected ? 'Please select at least one specialization.' : ''}
                                        </div>
                                    </div>
                                    <div className="form-group flex-fill mb-3">
                                        <label className="form-label small">University / Institute</label>
                                        <div className='searchfield-wrapper'>
                                            <input type="search" className='form-control'
                                                value={university}
                                                onChange={handleUniversityChange}
                                                placeholder='Eg. National Institute of Technology (NIT)' required />
                                            <img src={searchIcon} alt='Search Icon' className='position-absolute top-50 end-0 translate-middle z-1' />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label small">Course type</label>

                                        <div className='radio-wrapper d-flex flex-wrap gap-2'>
                                            {
                                                courseVal != "" ? <label className="bg-white small text-nowrap border rounded-pill px-3 py-2">{courseVal}
                                                    <span className='ms-2 pointer' type="button" onClick={() => {
                                                        setCourse("");
                                                        setIsValidCourse(false);
                                                    }}><i class="bi bi-x-lg"></i></span>
                                                </label> :
                                                    courseType?.map((course) => {
                                                        return (<div className="radio-item text-nowrap">
                                                            <label className="btn bg-white small border rounded-pill px-3 py-2"
                                                                onClick={() => {
                                                                    setCourse(course);
                                                                    setIsValidCourse(true);
                                                                }}
                                                            >{course}</label>
                                                        </div>)
                                                    })}

                                        </div>
                                        {isform2Submitted && !isCourseValid ? <div class="small text-danger">please select Course.</div> : null}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Starting year</label>
                                        <input type="number" min="2000" max="2023" step="1"
                                            maxLength={4}
                                            className='form-control'
                                            placeholder='Eg. 2023'
                                            value={numberValue}
                                            onChange={handleNumberInputChange}
                                            required />
                                        <div className='suggestion-wrapper d-flex flex-wrap gap-2 mt-2 pt-1'>
                                            {fiveYearsBefore?.map((year) => {
                                                return (<label className="bg-white small border rounded-pill px-3 py-2"
                                                    onClick={() => { setNumberValue(year) }}>{year}</label>
                                                )
                                            })}
                                        </div>
                                        {isform2Submitted && numberValue == "" ? <div class="small text-danger">please select starting Year.</div> : null}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Passing year</label>
                                        <input type="number" min="2000" max="2023" step="1" maxLength={4}
                                            className='form-control'
                                            value={passingYear}
                                            onChange={handlePassYearchange}
                                            placeholder='Eg. 2023' required />
                                        <div className='suggestion-wrapper d-flex flex-wrap gap-2 mt-2 pt-1'>
                                            {fiveYearLater?.map((year) => {
                                                return (<label className="bg-white small border rounded-pill px-3 py-2"
                                                    onClick={() => { setPassingYear(year) }}>{year}</label>
                                                )
                                            })}
                                        </div>
                                        {isform2Submitted && passingYear == "" ? <div class="small text-danger">please select Passing Year.</div> : null}

                                    </div>
                                    <div className='btn-wrapper mt-4'>
                                        <button
                                            className='btn btn-primary btn-custom px-4'
                                            onClick={() => submitStep2()}
                                        >Save and continue</button>
                                    </div>
                                </div>

                                {/* Step 3: Last section */}
                                <div id="step3" className='steps' style={{ display: 'none' }}>
                                    <div className='title-header mb-4'>
                                        <div className='fs-5 fw-semibold'>Add headline & preferences</div>
                                        <div className='small text-secondary'>Add preferences to get relevant job recommendations & make your profile stronger</div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label fs-5 small">Resume Headline</label>
                                        <textarea className="form-control" 
                                        id="textarea" rows="5"
                                        value={resumeHeadline}
                                        onChange={handleTextareaChange}   
                                        placeholder='Add resume headline and preferences to make your profile stronger and get relevant job recommendations' required></textarea>
                                        {validationError && <div className="small text-danger">{validationError}</div>}

                                    </div>
                                    <div className="form-group mb-3">
                                        <div className='suggestion-label text-secondary mb-2'>Suggestion</div>
                                        <div className="border rounded p-3">
                                            <div className="form-check">
                                                <input className="form-check-input mt-2" type="checkbox"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                name="flexRadioDefault" id="flexRadioDefault1" />
                                                <label className="form-check-label fs-18" >
                                                    <span className='job-title text-capitalize'>Web designer</span> with <span className='course text-capitalize'>BCA</span> in <span className='specialization text-capitalize'>Computers</span> currently living in <span className='location text-capitalize'>New Delhi</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='btn-wrapper mt-4'>
                                        <button  onClick={() => submitStep3()} className='btn btn-primary btn-custom px-4'>Submit</button>
                                    </div>
                                </div>

                                <div id="step5" className='steps'>
                                    <div className='title-header mb-4'>
                                        <div className='fs-5 fw-semibold'>Add your Skills</div>
                                        <div className='small text-secondary'>Details will help recruiters to understand your background.</div>
                                    </div>
                                   
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Current city</label>
                                        {/* <input type="text" className='form-control' placeholder='Mention the city you live in' required /> */}
                                        <div>
                                            {city != "" ? <label className="bg-white small text-nowrap border rounded-pill px-3 py-2">{city}
                                                <span className='ms-2 pointer' type="button" onClick={() => {
                                                    setCity("");
                                                    setAddress("");
                                                    setErrors((prevErrors) => ({
                                                        ...prevErrors,
                                                        city: '',
                                                    }));
                                                }
                                                }><i class="bi bi-x-lg"></i></span>
                                            </label> :
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={handleChange}
                                                    onSelect={handleSelect}
                                                    searchOptions={{ types: ['(cities)'] }} // Filter to get only city names
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search Cities...',
                                                                })}
                                                                className='form-control'
                                                            />
                                                            <div>
                                                                {suggestions.map((suggestion) => (
                                                                    <div
                                                                        key={suggestion.id}
                                                                        {...getSuggestionItemProps(suggestion)}
                                                                    >
                                                                        {renderSuggestion(suggestion)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            }
                                            {errors?.city ? <div class="small text-danger">{errors?.city}</div> : null}
                                        </div>
                                        <div className='suggestion-wrapper mt-2 pb-2'>
                                            <div className='suggestion-label small text-secondary mb-2'>Suggestion:</div>
                                            <div className='d-flex flex-wrap gap-2 text-capitalize'>
                                                {citySuggestion?.map((cityVal) => {
                                                    return (<label className="bg-white small text-nowrap border rounded-pill px-3 py-2"
                                                        onClick={() => {
                                                            setCity(cityVal)
                                                            setAddress("");
                                                            setErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                city: '',
                                                            }));
                                                        }}>{cityVal}</label>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                    </div>
                                   
                                    <div className="form-group mb-3">
                                        <label className="form-label small">Key skills</label>
                                        <CreatableSelect isMulti className='custom-select' classNamePrefix='custom-select' required
                                            closeMenuOnSelect={false}
                                            name="skills"
                                            options={SkillsVal}
                                            onChange={(value) => {
                                                setSelectedOption(value);
                                                setErrors((prevErrors) => ({
                                                    ...prevErrors,
                                                    skills: '',
                                                }));
                                            }
                                            }
                                            placeholder="Add your skills"
                                        />
                                        <div id="help" className="form-text">Keyskills are crucial for recruiters to hire you</div>
                                        <div className='suggestion-wrapper mt-2 pb-2'>
                                            {errors?.skills ? <div class="small text-danger">{errors?.skills}</div> : null}

                                            <div className='suggestion-label small text-secondary mb-2'>Suggestion:</div>
                                            <div className='d-flex flex-wrap gap-2'>
                                                <label className="bg-white small text-nowrap border rounded-pill px-3 py-2">HTML</label>
                                                <label className="bg-white small text-nowrap border rounded-pill px-3 py-2">CSS </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='btn-wrapper mt-4'>
                                        <button type='button' onClick={() => submitStep5()} className='btn btn-primary btn-custom px-4'>Save and continue</button>
                                    </div>
                                </div>             

                            </div>
                            {isLoading ? <div className='spinner-border text-primary' role='status'><span className='visually-hidden'>Loading...</span></div> : null}

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
