import React from 'react';
import { UserAuth } from './context/AuthContext';
import { NavLink, useNavigate } from 'react-router-dom';

const Header = () => {
  const { logOut, user } = UserAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  }
  // shorName method is calculating shortname
  const shortName = user?.displayName?.split(" ")?.map((shortName) => {
    return shortName[0];
  });
  
  return (
    <nav className="navbar navbar-dark bg-primary navbar-expand-lg top-header sticky-top py-2" data-bs-theme="dark">
      <div className="container">
        <NavLink to="/" className="navbar-brand d-none d-lg-flex align-items-center p-0">
          <div className='brand-name'>NM-Interview System Web</div>
          <div className="app-version d-grid gap-1">
            <div>BETA</div>
            <div>Version 1.1.5</div>
          </div>
        </NavLink>

        <button className="navbar-toggler border-0 p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#topNavbar" aria-controls="topNavbar"> 
          <span className="navbar-toggler-icon"></span>
        </button>

        <NavLink to="/" className="navbar-brand d-flex d-lg-none align-items-center p-0 px-2 m-0">
          <div className='brand-name'>NM-Interview System</div>
          <div className="app-version d-grid gap-1">
            <div>BETA</div>
            <div>Version 1.1</div>
          </div>
        </NavLink>

        <div className="offcanvas offcanvas-start text-bg-primary" tabIndex="-1" id="topNavbar" aria-labelledby="topNavbarLabel">
          <div className="offcanvas-header border-bottom border-secondary">
            <NavLink to="/" className="navbar-brand d-flex d-lg-none align-items-center p-0 m-0">
              <div className='brand-name'>NM-Interview System</div>
              <div className="app-version d-grid gap-1">
                <div>BETA</div>
                <div>Version 1.1</div>
              </div>
            </NavLink>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end">
              <li className="nav-item">
                {/* <NavLink to={'/admin-login'} className="nav-link text-white" aria-current="page" >Admin Login</NavLink> */}
              </li>
              {/* <li className="nav-item">
                <NavLink to='/finance-login' className="nav-link text-white">Finance Login</NavLink>
              </li> */}
            </ul>
          </div>
        </div>

        <li className="nav-item dropdown user-logout">
          <NavLink to="#" className="nav-link text-white text-center p-0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div className="chip-wrapper">
              <div className="chip-img d-flex align-items-center justify-content-center rounded-circle border border-white overflow-hidden">
                <div className="small user-shortname text-white">{shortName}</div>
                {/* <img className='w-100 h-100' src={require('./assets/Avatar.png')} alt='User Image' /> */}
              </div>
            </div>
          </NavLink>
          <ul className="dropdown-menu dropdown-menu-end caret-indicator shadow p-3 pb-4 mt-2" style={{ width: "300px" }}>
            <div className="d-flex justify-content-between gap-2">
              <div className="orgainization-name title-label fw-medium">Virtualemployee Pvt Ltd.</div>
              <NavLink className="signout title-label pointer text-primary" onClick={handleSignOut}>Sign out</NavLink>
            </div>
            <div className="d-flex align-items-center gap-2 mt-3">
              <div className="chip-wrapper">
                <div className="chip-img d-flex align-items-center justify-content-center rounded-circle border overflow-hidden" style={{ height: "70px", width: "70px" }}>
                  {/* <div className='user-shortname text-dark fs-3'>VA</div> */}
                  <img className="w-100 h-100" src={require('./assets/Avatar.png')} alt="User" />
                </div>
              </div>
              <div className="user-info overflow-hidden">
                <div className="user-name text-capitalize">{user?.displayName}</div>
                <div className="user-id title-label">{user?.email}</div>
                <div className="user-id title-label">
                  <div className="btn-link text-primary pointer" onClick={handleProfile}>View account</div>
                </div>
              </div>
            </div>
          </ul>
        </li>
      </div>
    </nav>
  );
};

export default Header;
