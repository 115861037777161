import React, { useEffect, useRef, useState, useContext } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import AWS from 'aws-sdk';
import moment from 'moment';
// Countdown Imports
import Countdown, { zeroPad } from 'react-countdown';
import ProfileContext from '../context/ProfileContext';
import { UserAuth } from '../context/AuthContext';
import '../App.css';
// Model Imports
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { javascript } from '@codemirror/lang-javascript';
import html2canvas from 'html2canvas';
import { Buffer } from "buffer";

const CodingTestScreen = () => {
  const { profile } = useContext(ProfileContext);
  const { user, logOut } = UserAuth();
  const navigate = useNavigate();

  const lambda = new AWS.Lambda({
    region: 'us-east-1',
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_ACCESSKEY,
  });

  const [question, setQuestion] = useState('');
  const [example, setExample] = useState('');
  const [code, setCode] = useState('');
  const [isProcessing, setProcessing] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [warning, setWarning] = useState(false);
  const [quesToken, setQuesToken] = useState(0);
  const [answerToken, setAnswerToken] = useState(0);
  const [quesTime, setQuesTime] = useState("");
  const [ansTime, setAnsTime] = useState("");
  const codeEditor = useRef(null);
  const [time, setTime] = useState(60 * 60); // Countdown time in seconds
  const intervalRef = useRef(null); // Create a reference to the interval
  // Model State & Function
  const [show, setShow] = useState(true);
  const [showover, setShowOver] = useState(true);

  const handleClose = () => {
    setShow(false);
    setShowOver(false);
    navigate('/account');
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  useEffect(() => {
     if(time == 300)
     setWarning(true);
     if(time < 280)
     {
      setWarning(false);
      setShow(false);
     }
     if(time < 3)
     {
      setShowOver(true);
      setCompleted(true);  
     } 

    if (time === 0) {
      setShow(false);
      setShowOver(false);
      setCompleted(false);
       navigate('/account');
      clearInterval(intervalRef.current); // Clear the interval if time reaches 0
      
    } else {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime - 1); // Update the countdown time
      }, 1000);
    }
    return () => clearInterval(intervalRef.current); // Clear the interval on unmount
  }, [time]);

  const minutesVal = Math.floor(time / 60);
  const secondsVal = time % 60;

  // Fetch Question Implementation
  // const fetchQuestion = async () => {
  //   setProcessing(true);
  //   try {
  //     if(!profile?.prefered_lang)
  //     {
  //       await logOut(); 
  //     }
  //     lambda.invoke({
  //       FunctionName: 'my-lambda-service-dev-interview',
  //       Payload: JSON.stringify({
  //         "command": "Need a coding question",
  //         "technology":profile?.prefered_lang,
  //         "difficulty": "Medium",
  //         "experience":profile?.total_exp
  //       }),
  //     }, (error, data) => {
  //       if (error) {
  //         console.error(error);
  //       } else {
  //         console.log(data);
  //         let dataVal = JSON.parse(data?.Payload);
  //         setQuestion(dataVal?.result?.question || "");
  //         setExample(dataVal?.result?.example || "");
  //         setProcessing(false); 
  //       }
  //     }); 

  //   } catch (error) {
  //     console.error(error);
  //     setProcessing(false);
  //     toast.warning('Click on next unable to fetch !', {
  //       position: toast.POSITION.BOTTOM_CENTER
  //   });
  //   }
  // };

  const fetchQuestion = async () => {
    
  
    setProcessing(true);
    try {
      if (!profile?.prefered_lang) {
        await logOut();
      }

      const response = await fetch('https://dev.virtualemployee.com/open-api/question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "command": "Need a coding question",
          "technology": profile?.prefered_lang,
          "difficulty": "Medium",
          "experience": profile?.total_exp
        }),
      });

      const data = await response.json();
      setQuestion("");
      setExample("");
      const { question, example } = data.result;
      setQuestion(question);
      setExample(example);
      setProcessing(false);
      setQuesToken(data?.total_tokens);
      setQuesTime(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));

    } catch (error) {

      console.error(error);
      setProcessing(false);
      toast.warning('Click on next unable to fetch !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      setQuesToken(0);
    }
  };

  // SubmitCode and Result API implmentations
  const submitCode = async () => {
    if(code == "")
       toast.warning('Please enter code !', {
      position: toast.POSITION.BOTTOM_CENTER
  }); 
  else
    try {
      setProcessing(true);
      const response = await fetch('https://dev.virtualemployee.com/open-api/api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question, code }),
      });
      const data = await response.json();
      //const result = await response.text();
      const { Question, Reason, Result } = data.result;
      saveResponse(Question, Reason, Result, data?.total_tokens)
      setProcessing(false);
      setAnswerToken(data?.total_tokens);
    } catch (error) {
      console.error(error);
      toast.error('Network error !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      setProcessing(false);
      setAnswerToken(0);
    }
  };

  const saveResponse = (question, Reason, Result,ansToken)=>{
    let mysqlTimestamp = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
    var startTime = moment(quesTime, "YYYY-MM-DD HH:mm:ss");
    var endTime = moment(mysqlTimestamp, "YYYY-MM-DD HH:mm:ss");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = duration.hours();
    var minutes = duration.minutes();
    var seconds = duration.seconds();
    var timeDifference = hours + ":" + String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0");

    const submitdata = {
      question: question,
      result: Result,
      reason: Reason,
      user_id: profile?.user_id,
      test_id: profile?.test_id,
      iscorrect: Result == "Pass" ? 1 : 0,
      email: user?.email,
      display_name:user?.displayName,
      operation:"insert",
      ques_token:quesToken,
      ans_token:ansToken,
      ques_time:quesTime,
      ans_time: mysqlTimestamp,
      time_diff: timeDifference
    }
    lambda.invoke({
      FunctionName: 'quesreplyFunc',
      Payload: JSON.stringify(submitdata),
    }, (error, data) => {
      if (error) {
        console.error(error);
        toast.error('Network error !', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      } else {
        let dataVal = JSON.parse(data?.Payload);

        toast.success('Record Saved !', {
          position: toast.POSITION.BOTTOM_CENTER
      });
      handleUploadClick(dataVal?.insertId);
      }
    }); 
  
    fetchQuestion();
  }

  // Set the CodeMirror Value in value state
  const onChange = React.useCallback((value, viewUpdate) => {
    setCode(value)
  }, []);

  // Random countdown component
  const Completionist = () => {
    return (
      <Modal centered show={showover} backdrop="static" keyboard={false} contentClassName="rounded-0 rounded-bottom text-center">
        <Modal.Body className='border-5 border-top border-primary p-4 p-md-5'>
          <div className='mx-auto' style={{ maxWidth: '320px' }}>
            <div className='text-warning mb-3 lh-1'><i className="bi bi-exclamation-circle h1 lh-1"></i></div>
            <div className='text-secondary mb-3'>Thanks for being with us for 1 hour.</div>
            <Button variant="primary" onClick={handleClose} className="px-4">OK</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  };
  const WarningModal = () => {
    return (
      <Modal centered show={show} backdrop="static" keyboard={false} contentClassName="rounded-0 rounded-bottom text-center">
        <Modal.Body className='border-5 border-top border-primary p-4 p-md-5'>
          <div className='mx-auto' style={{ maxWidth: '320px' }}>
            <div className='text-warning mb-3 lh-1'><i className="bi bi-exclamation-circle h1 lh-1"></i></div>
            <div className='text-secondary mb-3'>You have only remaining 5 min for you last Submission.</div>
            <Button variant="primary" onClick={() => {
              setWarning(false);
              setShow(false);
            }} className="px-4">OK</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  };


  //const Completionist = () => <div className='text-success fs-5 fw-semibold'>Time is about to over</div>;

  // Renderer callback with condition
  const RenderTimer = () => {
    if(completed) {
      return <Completionist />
    }
    if(warning)
    {
     return <WarningModal/> 
    }
    
    else {
      // Render a countdown
      return (
        <span className='text-danger fs-5 fw-semibold'>
          <i className="bi bi-stopwatch me-1"></i>{minutesVal}:{secondsVal}
        </span>
      );
    }
  };

  function handleUploadClick(quesID="test") {
    let todayDate = moment(Date.now()).format('YYYY-MM-DD');
    if (!codeEditor.current) {
      return;
    }

    // html2canvas(codeEditor.current).then(function (canvas) {
    //   const link = document.createElement('a');
    //   link.download = 'screenshot.png';
    //   link.href = canvas.toDataURL('image/png');
    //   link.click();
    // });
    // Take screenshot of CodeMirror area and upload to S3 bucket
    // html2canvas(codeEditor.current).then(function (canvas) {
    //   const s3 = new AWS.S3({
    //     region: 'us-east-1',
    //     accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    //     secretAccessKey: process.env.REACT_APP_ACCESSKEY
    //   });
      
      
    //   const base64Data = canvas.toDataURL('image/png').replace(/^data:image\/png;base64,/, '');
    //   const params = {
    //     Bucket: 'test.virtualemployee.com',
    //     Key: `${profile?.user_id}/${todayDate}/${profile?.test_id}/${quesID}.png`,
    //     Body: Buffer.from(base64Data, 'base64'),
    //     ContentType: 'image/png',
    //     ACL: 'public-read',
    //   };
    //   s3.upload(params, function(err, data) {
    //     if (err) {
    //       console.error(err);
    //       setCode("");
    //     } else {
    //       // console.log('Upload successful:', data.Location);
    //       setCode("");
    //     }
    //   });
    // });
  }


  return (
    <div className='coding-test-page py-5'>
      {isProcessing ? <div className="loader-wrapper">
        <div className="loader"></div>
      </div> : null}

      <div className='container'>
        <div className='d-flex flex-wrap align-items-center justify-content-between gap-2 mb-3'>
          <div className='heading-title fs-3 fw-bold'>Question and Code Submission</div>
          <div className='counter-wrapper'>
            <RenderTimer />
          </div>
        </div>
        <div className='coding-area card py-4 px-3 p-md-5 shadow-sm'>
        <div id="code-editor" ref={codeEditor}>
          <div id="question-view">
            <div className='d-flex flex-column flex-md-row gap-md-2 mb-3'>
              <div className='question-label fw-bold'>Question:</div>
              <div className='question text-secondary'>{question}</div>
            </div>
            <div className='d-flex flex-column flex-md-row gap-md-2 mb-3'>
              <div className='question-label fw-bold'>Example:</div>
              <div className='question text-secondary'>{example}</div>
            </div>
          </div>
          <label htmlFor="code" className='form-label text-secondary fw-medium'>Write code here:</label>
          
          <CodeMirror value={code} height="200px" onChange={onChange} extensions={[javascript({ jsx: true })]}/>
          </div>
          <div className='btn-wrapper mt-4'>
          <button className='btn btn-primary me-2' onClick={fetchQuestion}>Next</button>
          <button className='btn btn-primary float-end' onClick={handleUploadClick}>Submit Answer</button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CodingTestScreen;


