import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import Webcam from "react-webcam";
import AWS from 'aws-sdk';
import { Buffer } from "buffer";
import ProfileContext from '../context/ProfileContext';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from 'axios';
import VideoRecorder from './VideoRecorder';
import { useNavigate } from 'react-router-dom';
import whatsappIcon from '../assets/whatsapp.svg'

export const ProfileVerify = () => {
  const navigate = useNavigate();
  const { user, logOut } = UserAuth();
  const webcamRef = useRef(null);
  const webcamRefNew = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [hasPermission, setHasPermission] = useState(null);
  const { profile } = useContext(ProfileContext);
  const [file, setFile] = useState(null);
  const acceptedFileTypes = ['docx', 'pdf', 'doc'];
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [currInx, setIdx] = useState(0);
  const [phone, setPhone] = useState("");
  // whatsapp
  const [whatsapp, setWhatsapp] = useState("");
  const [showWhatsapp, setShowwhatsapp] = useState(false)
  const [whatsappCountry, setwhatsappCountry] = useState("")
  const [iswhatsappNumber, setIswhatsappNumber] = useState("");
  // whatsapp
  const [country, setCountry] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [recordedChunks, setRecordedChunks] = useState([]);
  let currentSectionIndex = 0;
  const [randomNum, setRandom] = useState(Math.floor(Math.random() * 101));

  // RandomTextReader
  const nouns = ['cat', 'dog', 'tree', 'house', 'car'];
  const verbs = ['runs', 'jumps', 'sleeps', 'eats', 'drinks'];
  const adjectives = ['happy', 'big', 'beautiful', 'colorful', 'smart'];
  const [randomSentences, setRandomSentences] = useState({ sentence1: '', sentence2: '' });

  // audio
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonsave, setIsButtonsave] = useState(true);
  const [isButtonstop, setIsButtonstop] = useState(true);
  const [isrecordingdisable, setRecordingdisable] = useState(true);

  const [selectedOption, setSelectedOption] = useState('');

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(mediaRecorder);

        mediaRecorder.ondataavailable = (e) => {
          setAudioChunks([...audioChunks, e.data]);
        };
        mediaRecorder.start();
        setRecordingdisable(!isrecordingdisable);
        setIsButtonstop(false)
      })
      .catch((err) => {
        console.error('Error accessing microphone:', err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    setIsButtonsave(false)
    setIsButtonstop(true)
  };

  const saveAudio = async () => {
    toast.success('Please wait while data is uploading.', {
      position: toast.POSITION.BOTTOM_CENTER
    });
    setLoading(true);
    setIsButtonstop(true)
    // if (mediaRecorder) {
    //   mediaRecorder.stop();
    // }
    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
    const formData = new FormData();
    // console.log(audioBlob)
    formData.append('smaple_audio_url', audioBlob);
    // formData.append('email', "radheshyam.kori@gmail.com");
    formData.append('email', user?.email);
    // header
    // const customToken = admin.auth().createCustomToken(phone, {
    //   role: 'user', // Set custom claims about the user
    // }).then((token)=>{
    //   console.log(token)
    // })
    // const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
    // const headers = {
    //   'Authorization': `Bearer ${refreshedToken}`,
    //   'Content-Type': 'application/json',
    // };
    // header
    try {
        const response = await fetch(`${process.env.REACT_APP_APIURL_NMVE}/v3.0/audio/uploadSample`, {
        // headers: headers,
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.statusCode == 200) {
        toast.success('File has been uploaded.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        setLoading(false);
        setIsButtonsave(true)
        setIsButtonDisabled(!isButtonDisabled);
        // setError('Please wait while data is uploading.!')
      } else {
        setLoading(true);
        setIsButtonsave(true)
        // setError('Please wait while data is uploading.!')
        toast.error('Network Error!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
      // Set the download URL for saving the file from React.js to a folder 
      setDownloadUrl(URL.createObjectURL(audioBlob));
    } catch (error) {
      console.error('Error saving audio:', error);
    }
  };
  // audio

  const generateRandomSentences = () => {
    const randomNoun1 = nouns[Math.floor(Math.random() * nouns.length)];
    const randomVerb1 = verbs[Math.floor(Math.random() * verbs.length)];
    const randomAdjective1 = adjectives[Math.floor(Math.random() * adjectives.length)];
    const sentence1 = `The ${randomAdjective1} ${randomNoun1} ${randomVerb1} in the garden.`;

    const randomNoun2 = nouns[Math.floor(Math.random() * nouns.length)];
    const randomVerb2 = verbs[Math.floor(Math.random() * verbs.length)];
    const randomAdjective2 = adjectives[Math.floor(Math.random() * adjectives.length)];
    const sentence2 = `A ${randomAdjective2} ${randomNoun2} ${randomVerb2} near the river.`;

    setRandomSentences({ sentence1, sentence2 });
  };

  // useEffect(()=>{

  // },[])
  // RandomTextReader

  useEffect(() => {
    // console.log(user)
    document.getElementById("otpVerification").style.display = "none";
    const sectionsData = document.querySelectorAll('form > div');
    setSections(sectionsData);
    showSection(currInx);
    generateRandomSentences()
  }, []);

  const handleOnChange = (value, country) => {
    setPhone(value);
    setCountry(country);
  };

  const handleDrop = useCallback((acceptedFiles) => {
    setError("");
    setLoading(false);
    const selectedFile = acceptedFiles[0];
    if (acceptedFiles?.length == 0) {
      setError(`File ${selectedFile.name} has an invalid file type. Only ${acceptedFileTypes.join(', ')} are accepted.`);
      return;
    }
    setFile(selectedFile);
  }, []);


  function Dropzone({ onDrop }) {
    const onDropCallback = useCallback((acceptedFiles) => {
      onDrop(acceptedFiles);
    }, [onDrop]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: onDropCallback,
      accept: acceptedFileTypes.join(','),
    });

    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='upload-icon'><i class="bi bi-cloud-arrow-up-fill fs-1 text-success"></i></div>
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <div className='text-muted'>
              <p>Drop your file here or Browse.<br /> File should be (DOCX, PDF, DOC ) only</p>
              <button className='btn btn-success px-4'>Browse File</button>
            </div>
        }
      </div>
    );
  }

  const handleUserMedia = stream => {
    setHasPermission(true);
  };

  const handleUserMediaError = () => {
    setHasPermission(false);
    setShowCamera(false);
  };

  const lookupPhoneNumber = async (phoneNumber) => {
    try {
      const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json',
      };
      let payloadData = {
        "phoneNumber": phoneNumber,
      };

      const response = await axios.post("https://dev.virtualemployee.com/admin-panel/api/v3.0/s3/look-up-phone-number", payloadData, { headers });
      const data = response.data?.data;
      return data?.success;
    } catch (err) {
      //console.error(err);
      //throw err;
      return false;
    }
  };

  async function generateOtp() {
    try {
      let phnwithcode = '+' + phone;
      setLoading(true);

      let isValid = await lookupPhoneNumber(phnwithcode);
      // console.log("isValid");
      // console.log(isValid);
      // const isValid = true;
      if (isValid) {
        const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
        const headers = {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'application/json',
        };

        const submitdata = {
          login_token: user?.uid,
          to: phnwithcode,
          operation: 'sendotp',
          is_whatsapp: iswhatsappNumber == "Yes" ? 1 : 0
        };
        // const response = await axios.post(process.env.REACT_APP_PROFILEFUNC, submitdata, {
          const response = await axios.post(`${process.env.REACT_APP_APIURL_NMVE}/v3.0/otp/sendForWeb`, submitdata, {
          headers: headers,
        });

        setLoading(false);
        toast.success('Otp Sent !', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        document.getElementById("numVerification").style.display = "none";
        document.getElementById("otpVerification").style.display = "block";
      } else {
        setLoading(false);
        toast.error('Number is not valid !', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error('Network error !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }

  const handleChange = (event, index) => {
    const newOtp = [...otp];
    const { maxLength, value, name } = event.target;

    newOtp[index] = value;
    setOtp(newOtp);
    const [fieldName, fieldIndex] = name.split("-");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 4) {
        const nextSibling = document.querySelector(`input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`);
        if (nextSibling !== null) { nextSibling.focus(); }
      }
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const enteredOtp = otp.join('');
      if (enteredOtp?.length !== 4) {
        toast.error('Please enter a 4-digit OTP!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      } else {
        setLoading(true);
        const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
        const headers = {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'application/json',
        };

        const submitdata = {
          operation: 'verify',
          login_token: user?.uid,
          otp: enteredOtp,
        };

        const response = await axios.post(`${process.env.REACT_APP_APIURL_NMVE}/v3.0/otp/verifyOTPForWeb`, submitdata, {
        // const response = await axios.post(process.env.REACT_APP_PROFILEFUNC, submitdata, {
          headers: headers,
        });

        setLoading(false);
        const dataVal = response.data;
        if (dataVal?.verified) {
          toast.success('Otp Matched !', {
            position: toast.POSITION.BOTTOM_CENTER
          });
          nextSection();
        } else {
          toast.error('Otp does not Matched !', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error('Network error !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  function showSection(sectionIndex) {
    sections.forEach((section, index) => {
      if (index === sectionIndex) {
        section.style.display = 'block';
      } else {
        section.style.display = 'none';
      }
    });
    // currentSectionIndex = sectionIndex;
    setIdx(sectionIndex);
  }

  function previousSection() {
    if (currInx > 0) {
      setIdx(currInx - 1);
      showSection(currInx - 1);
      let currIdxNew = currInx - 1;
      let stepperVal = document.getElementById("stepNav" + currIdxNew);
      stepperVal.classList.add('active');
    }
  }

  function nextSection() {
    if (currInx < sections.length - 1) {
      setIdx(currInx + 1);
      showSection(currInx + 1);
      let currIdxNew = currInx + 1;

      let nextstepper = currIdxNew + 1;
      let stepperVal = document.getElementById("stepNav" + nextstepper);
      stepperVal.classList.add('active');
      stepperVal.previousElementSibling.classList.add('completed')
      stepperVal.previousElementSibling.classList.remove('active')
    }
  }

  const capture = () => {
    try {
      navigator.mediaDevices.getUserMedia({ audio: false, video: true })
        .then(async () => {
          if (webcamRef && webcamRef?.current) {
            const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
            const headers = {
              'Authorization': `Bearer ${refreshedToken}`,
              'Content-Type': 'application/json',
            };
            setLoading(true);
            setHasPermission(true);
            const imageSrc = webcamRef.current.getScreenshot();
            const imageName = `${user?.uid}.jpg`;
            let payloadData = {
              // "bucketName": "interviewer-profile-test",
              "bucketName": "interviewer-profile",
              "key": `${user?.uid}/${imageName}`,
              "Body": imageSrc.replace(/^data:image\/\w+;base64,/, ""),
              "ContentEncoding": 'base64',
              "contentType": 'image/jpeg',
              "isEncoding": true,
            };
            const response = await axios.post(process.env.REACT_APP_S3UPLOAD, payloadData, { headers });
            setImgSrc(imageSrc);
            setShowCamera(false);
            saveProfileInfo("updateUserProfile", response.data.uploadResponse, "updatePhoto")
            const mediaStream = webcamRef.current.video.srcObject;
            const tracks = mediaStream.getTracks();
            tracks.forEach(track => track.stop());
            webcamRef.current.video.srcObject = null;


          }
          else {
            setHasPermission(false)
            setShowCamera(false);
            setLoading(false);

          }
        })
        .catch((err) => {
          console.log("error Founf", err);
          setHasPermission(false)
          setShowCamera(false);
        });
      // Do something with the image, e.g. upload it to a server
    } catch (error) {
      setHasPermission(false);
      setShowCamera(false);

    }
  };

  const handleTurnOnCamera = async () => {
    setLoading(false);
    navigator.mediaDevices.getUserMedia({ audio: false, video: true })
      .then(() => {
        setHasPermission(true)
        setShowCamera(true);
        setImgSrc("");
      })
      .catch((err) => {
        console.log("error Founf", err);
        setHasPermission(false)
        setShowCamera(false);
        setImgSrc("");
      });
  };

  //Save Profile data to DB

  const saveProfileInfo = async (actionType, Data, action) => {
    try {
      const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json',
      };

      const submitdata = {
        operation: actionType,
        login_token: user?.uid,
        url: Data,
        action: action
      };
      // console.log(submitdata)
      // const response = await axios.post(`${process.env.REACT_APP_APIURL}/updateProfile`, submitdata, {
      const response = await axios.post(process.env.REACT_APP_APIURL, submitdata, {
        headers: headers,
      });

      const dataVal = response.data;
      setLoading(false);
      toast.success('Record Saved !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      nextSection();
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error('Network error !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  //Upload resume Function
  const uploadResume = async () => {
    const checkbox = document.getElementById('termsCheck1');
    // console.log(checkbox.checked);
    if (error === "") {
      if (checkbox.checked && file) {
        setLoading(true);
        const fileVal = file || {};
        const fileName = file?.name;
        const fileType = file?.type;

        try {
          // Get the Firebase authentication token
          const refreshedToken = await user?.getIdToken(true);

          // Include the token in the request headers
          const headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'multipart/form-data', // Set content type to form data
          };

          const formData = new FormData();
          formData.append('file', fileVal);
          formData.append('bucketName', 'interviewer-profile-test');
          formData.append('key', `${user?.uid}/Resume/${fileName}`);
          formData.append('contentType', fileType);
          formData.append('isEncoding', false);
          formData.append('ContentEncoding', '');
          const response = await axios.post(process.env.REACT_APP_S3UPLOAD, formData, { headers });
          // console.log(response.data.uploadResponse);
          // Assuming your API returns some data, you can handle it here
          saveProfileInfo("updateUserProfile", response.data.uploadResponse, "updateResume");
          // saveProfileInfo("updateUserProfile", "response.data.uploadResponse", "updateResume");
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
      setError("Please select appropriate file type and mark agree to upload.");
      return;
    }
  };

  const updateProfile = async () => {
    try {
      setLoading(true);

      const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json',
      };

      const submitdata = {
        operation: 'verifyVideo',
        login_token: user?.uid,
        is_video_verified: 1,
        is_profile_verified: 1
      };

      // const response = await axios.post(`${process.env.REACT_APP_PROFILEFUNC}/verifyVideo`, submitdata, {
      const response = await axios.post(process.env.REACT_APP_PROFILEFUNC, submitdata, {
        headers: headers,
      });

      setLoading(false);
      toast.success('Profile updated!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      nextSection();
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error('Network error !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  const verifyVideo = async (isfaceMatched, isTextMatched) => {
    if (isfaceMatched || isTextMatched) {
      await updateProfile();
      toast.success('Thanks, Video verification done !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      //nextSection();
    }
    else if (isfaceMatched && !isTextMatched) {
      toast.error('written text is not matched !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
    else if (!isfaceMatched && isTextMatched) {
      toast.error('your face is not recognized !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
    else {
      toast.error('Not able to verify your identity please try again !', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }

  const generateNewRand = () => {
    setRandom(Math.floor(Math.random() * 101));
  }

  const editNumber = () => {
    document.getElementById("otpVerification").style.display = "none";
    document.getElementById("numVerification").style.display = "block";
  };

  const handleOptionChange = (event) => {
    setIswhatsappNumber(event.target.value)
    setSelectedOption(event.target.value);
    // console.log(event.target.value)
    if(event.target.value === "Yes"){
      setShowwhatsapp(false)
    }else if(event.target.value === "No"){
      setShowwhatsapp(true)
    }
  };

  const handleWhatsappNum = (value, country) => {
    setWhatsapp(value);
    setwhatsappCountry(country);
  }

  return (
    <div className='profile-verify-page py-4'>
      <div className='container'>
        <div className='stepbar-wrapper mx-auto' style={{ maxWidth: '740px' }}>
          {/* Stepper Navigation */}
          <ul id="stepBar" className='stepper-nav d-flex align-items-center justify-content-between mb-5 pb-xxl-3'>
            <li className="active text-center" id="stepNav1">
              <div className='step-count d-flex align-items-center justify-content-center'></div>
              <div className='step-name'>Upload Photo</div>
            </li>
            <li className=" text-center" id="stepNav2">
              <div className='step-count d-flex align-items-center justify-content-center'></div>
              <div className='step-name'>Upload CV</div>
            </li>
            <li className="text-center" id="stepNav3">
              <div className='step-count d-flex align-items-center justify-content-center'></div>
              <div className='step-name'>OTP Verification</div>
            </li>
            {/* <li className="text-center" id="stepNav4">
              <div className='step-count d-flex align-items-center justify-content-center'></div>
              <div className='step-name'>Audio Verification</div>
            </li> */}
            <li className="text-center" id="stepNav4">
              <div className='step-count d-flex align-items-center justify-content-center'></div>
              <div className='step-name'>Audio Verification</div>
            </li>
            <li className="text-center" id="stepNav5">
              <div className='step-count d-flex align-items-center justify-content-center'></div>
              <div className='step-name'>Video Verification</div>
            </li>
            <li className="text-center" id="stepNav6">
              <div className='step-count d-flex align-items-center justify-content-center'></div>
              <div className='step-name'>Completed</div>
            </li>
          </ul>
        </div>

        <div className='stepbar-wrapper mx-auto' style={{ maxWidth: '800px' }}>
          <div className='card p-3 px-md-5 py-5 rounded-2 mx-auto text-center'>
            <form onSubmit={e => e.preventDefault()}>
              {/* Step 1: Upload Profile From Camera */}
              <div id="step1" className='steps user-img-area'>
                {hasPermission && imgSrc == "" ?
                  <>
                    <Webcam
                      onUserMedia={handleUserMedia}
                      onUserMediaError={handleUserMediaError}
                      audio={false}
                      videoConstraints={{ facingMode: "user" }}
                      width={250}
                      height={300}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      className='user-img img-fluid border border-2 rounded-circle shadow border-secondary p-1' alt="User"
                    />
                    {hasPermission === null && <p className='text-primary fw-medium'>Checking camera permission...</p>}
                    {hasPermission === true && <p className='text-success fw-medium'><i class="bi bi-check-circle-fill"></i> Camera permission granted!</p>}
                    {hasPermission === false && <p className='text-danger fw-medium'><i class="bi bi-exclamation-triangle-fill"></i> Camera permission denied!</p>}
                  </>
                  :
                  imgSrc != "" ? <img src={imgSrc} className='user-img img-fluid border border-2 rounded-circle shadow border-secondary p-1' alt="User" /> :
                    <>
                      <img src={require('../assets/Avatar.png')} className='user-img img-fluid border border-2 rounded-circle shadow border-secondary p-1' alt="User" />
                      {hasPermission === false && <p className='text-danger fw-medium'><i class="bi bi-exclamation-triangle-fill"></i> Camera permission denied!</p>}
                    </>
                }
                <div className='user-name fs-4 fw-medium mt-3'>{user?.displayName}</div>
                <div className='text-muted'>Please make sure your photo clearly shows your face</div>
                {isLoading ? <div className='spinner-border text-primary' role='status'><span className='visually-hidden'>Loading...</span></div> : null}

                {showCamera ? <button className='btn btn-success w-100 mt-5' onClick={capture}><i className="bi bi-camera me-1"></i>Upload your photo from camera</button>
                  : <button className='btn btn-success w-100 mt-5' onClick={handleTurnOnCamera}><i className="bi bi-camera me-1"></i>Turn on your webcam</button>
                }
                <div className='btn-wrapper text-center mt-3'>
                  <button className='btn btn-primary w-100 px-4' onClick={() => nextSection()}>Next</button>
                </div>
              </div>

              {/* Step 2: Upload Resume */}
              <div id="step2" className='steps'>
                <div className='resume-sec'>
                  <div className='fs-5 fw-semibold mb-3'>Upload your resume</div>
                  <label htmlFor="uploadResume" className="d-flex align-items-center justify-content-center bg-warning bg-opacity-10 border-warning rounded-3">
                    <div className='w-100'>
                      <Dropzone acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword']} onDrop={handleDrop} />
                      {file ?
                        <p key={file.name} className='bg-light border py-2 px-3 rounded-pill small text-truncate mx-auto mt-3' style={{ maxWidth: '300px' }}>
                          {file.name} ({file.size} bytes)
                        </p> : null
                      }
                    </div>
                  </label>
                  {error && <p className="text-danger mt-2">{error}</p>}
                </div>

                <div className="mt-3 form-check text-start">
                  <input type="checkbox" className="form-check-input" id="termsCheck1" required />
                  <label className="form-check-label" htmlFor="termsCheck1">Make sure all the information given in your CV are correct</label>
                </div>
                <div className='btn-wrapper text-center mt-4'>
                  <button className='btn btn-primary w-100 d-flex align-items-center justify-content-center px-4' onClick={() => uploadResume()}>Next
                    {isLoading ? <div className='spinner-border ms-2 border-2' role='status' style={{ width: '20px', height: '20px', borderWidth: '2px' }}><span className='visually-hidden'>Loading...</span></div> : null}
                  </button>
                </div>
                {/* <div className='btn-wrapper text-center mt-3'>
                  <button className='btn btn-primary w-100 px-4' onClick={() => nextSection()}>Next</button>
                </div> */}
              </div>

              {/* Step 3: Mobile Number Verification */}
              <div id="step3" className='steps mob-num-verify'>
                <div id="numVerification" className='num-verification'>
                  <div className='mb-4'>
                    <img src={require('../assets/mobile.jpg')} className='img-fluid mb-3' alt='Mobile Number Verify' />
                    <div className='fs-5 fw-semibold mb-1'>Enter your phone number</div>
                    <div className='small'>We will send you the <span className='fw-semibold'>4 digit</span> verification code</div>
                  </div>

                  <div className='input-cover mb-4'>
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                      }}
                      country={"in"}
                      value={phone}
                      onChange={handleOnChange}
                      enableSearch={true}
                      countryCodeEditable={false}
                      inputClass="w-100 shadow-sm py-2"
                      dropdownClass="text-start"
                      inputStyle={{ height: 'auto', minHeight: '45px' }}
                    />
                  </div>

                  {/* WhatsappNumber */}
                  <div className="text-start mb-4">
                    <label class="form-label w-100 mb-1">Please confirm, are you using this number for <span><img src={whatsappIcon} alt="Whatsapp" /></span>whatsapp too?</label>
                    <div className="radio-wrapper">
                      <div class="form-check form-check-inline me-4">
                        <input class="form-check-input border border-secondary border-opacity-75" type="radio" value="Yes" checked={selectedOption === "Yes"} onChange={handleOptionChange} name="whatsappNum" id="Yes" required />
                        <label class="form-check-label" for="Yes">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input border border-secondary border-opacity-75" type="radio" value="No" checked={selectedOption === "No"} onChange={handleOptionChange} name="whatsappNum" id="No" />
                        <label class="form-check-label" for="No">No</label>
                      </div>
                    </div>
                  </div>
                  {/* WhatsappNumber */}
                  {/* {showWhatsapp ? 
                  <div className='input-cover mb-4'>
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                      }}
                      country={"in"}
                      value={whatsapp}
                      onChange={handleWhatsappNum}
                      enableSearch={true}
                      countryCodeEditable={false}
                      inputClass="w-100 shadow-sm py-2"
                      dropdownClass="text-start"
                      inputStyle={{ height: 'auto', minHeight: '45px' }}
                    />
                  </div>
                  : ""} */}
                  <button className='btn btn-danger w-100 d-flex align-items-center justify-content-center' onClick={generateOtp}>Generate OTP
                    {isLoading ? <div className='spinner-border ms-2 border-2' role='status' style={{ width: '20px', height: '20px', borderWidth: '2px' }}><span className='visually-hidden'>Loading...</span></div> : null}
                  </button>
                </div>

                <div id="otpVerification" className='otp-verification' >
                  <div className='mb-3'>
                    <img src={require('../assets/mobile2.jpg')} className='img-fluid mb-3' alt='Mobile Number Verify' />
                    <div className='fs-5 fw-semibold mb-1'>OTP Verification</div>
                    <div className='small'>Enter OTP sent to <span className='fw-semibold'>+{phone}</span><i class="bi bi-pencil-fill ms-2 fs-6 text-danger" onClick={editNumber}></i></div>
                  </div>

                  <div className='enter-otp input-cover d-flex justify-content-center gap-2 mb-4'>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={digit}
                        name={`ssn-${index + 1}`}
                        className="form-control shadow-sm py-2"
                        onChange={(event) => handleChange(event, index)}
                      />
                    ))}
                  </div>

                  <div className='mb-4'>Don't receive OTP? <span className='text-danger text-uppercase fw-medium' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={generateOtp}>Resend</span></div>
                  {isLoading ? <div className='spinner-border ms-2 border-2' role='status' style={{ width: '20px', height: '20px', borderWidth: '2px' }}><span className='visually-hidden'>Loading...</span></div> : null}

                  <button className='btn btn-danger w-100 d-flex align-items-center justify-content-center' onClick={handleSubmit}>Verify & Continue</button>
                </div>
                {/* <button className='btn btn-primary w-100 px-4' onClick={() => nextSection()}>Next</button> */}
              </div>

              {/* Step 4: Audio Print  for Verification Process */}
              <div id="step4" className='steps'>
                <div className='fs-5 fw-bold mb-2'>Audio Print  for Verification Process</div>
                <div className='small text-muted mb-4 fs-18 fw-bold'>
                  Please read the text below and save it.
                </div>

                <div className='verification-demo border border-2 rounded-3 mb-4 px-3 py-5'>
                  <p className='mb-1 fs-18'>{randomSentences.sentence1}</p>
                  <p className='fs-18'>{randomSentences.sentence2}</p>
                  <div className='d-flex flex-wrap gap-2 justify-content-center'>
                    {/* <button onClick={startRecording} className='btn btn-success rounded-pill mt-4 px-3' disabled={!isrecordingdisable}><i className="bi bi-mic-fill"></i> Start Recording</button>
                    <button onClick={stopRecording} className='btn btn-danger rounded-pill mt-4 px-3' disabled={isButtonstop}><i className="bi bi-mic-mute-fill"></i> Stop Recording</button> */}
                    {isButtonstop ? <button onClick={startRecording} className='btn btn-success rounded-pill mt-4 px-3' disabled={!isrecordingdisable}><i className="bi bi-mic-fill"></i> Start Recording</button>
                      :<button onClick={stopRecording} className='btn btn-danger rounded-pill mt-4 px-3' disabled={isButtonstop}><i className="bi bi-mic-mute-fill"></i> Stop Recording
                      {!isLoading ? <div className='spinner-border ms-2 border-2' role='status' style={{ width: '20px', height: '20px', borderWidth: '2px' }}><span className='visually-hidden'>Loading...</span></div> : null}
                      </button>
                    }
                    <button onClick={saveAudio} className='btn btn-warning rounded-pill mt-4 px-3' disabled={isButtonsave}><i className="bi bi-save2"></i> Save Recording
                      {isLoading && isButtonstop ? <div className='spinner-border ms-2 border-2' role='status' style={{ width: '20px', height: '20px', borderWidth: '2px' }}><span className='visually-hidden'>Loading...</span></div> : null}
                    </button>
                  </div>
                </div>
                <div className='btn-wrapper text-center mt-3'>
                  <button className='btn btn-primary w-100 px-4' onClick={() => nextSection()} disabled={isButtonDisabled}>Next</button>
                  {/* <button className='btn btn-primary w-100 px-4' onClick={() => nextSection()}>Next</button> */}
                </div>
              </div>

              {/* Step 5: Video Verification */}
              <div id="step5" className='steps'>
                <div className='fs-5 fw-bold mb-2'>Video Verification in Next Step</div>
                <div className='small text-muted mb-4'>
                  Hold a white page in front of the webcam as demonstrated in the sample photo, similar to how the lady is holding it.
                  After receiving your unique code, write it clearly on a blank page and show it in front of the webcam. Ensure both your
                  face and the paper with the text are clearly visible to the webcam.
                </div>

                <div className='verification-demo ratio ratio-16x9 border border-2 rounded-3 mb-4'>
                  <img src={require('../assets/verification-demo1.png')} className='img-fluid rounded-3' alt='Verification' />
                </div>

                <div className='small fw-semibold text-danger mb-3'>
                  Ensure you have a blank sheet of white paper and a pen ready before clicking the "Get Your Unique Code" button.
                </div>
                <button className='btn btn-success w-100' data-bs-toggle="modal" data-bs-target="#videoVerifyModel">Get Your Unique Code</button>
              </div>

              {/* Step 6: Verification Successfull */}
              <div id="step6" className='steps'>
                <div className='mb-5'>
                  <img src={require('../assets/successfull.png')} className='img-fluid mb-3' alt='Mobile Number Verify' style={{ maxWidth: '120px' }} />
                  <div className='fs-5 fw-semibold'>Verification Completed</div>
                  <div className='text-muted'>You have successfully completed all your verification steps</div>
                </div>
                <button onClick={() => {
                  //navigate('/account');
                  navigate('/complete-profile');
                }} className='btn btn-primary w-100'>Update Details</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Video Verification Modal */}
      {/* <div className="video-verify-model modal fade" id="videoVerifyModel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={- 1} aria-labelledby="videoVerifyModelLabel" aria-hidden="true" >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content rounded-3">
            <div className="modal-body px-3 py-4 p-md-5 text-center">
              <div id='uniqueCode' className='mb-4'>
                <div className='fs-5 fw-bold mb-2'>Your Unique Code</div>
                <div className='text-muted mb-4'>Ensure both your face and the paper with the text are clearly visible to the webcam.</div>
                <div className='verification-code display-3 fw-bold mb-4 py-xl-4'>{randomNum}</div>
                <div className='mb-4 mb-md-5'>
                  <div className='text-muted'>Write it clearly on a blank page and show it in front of the webcam.</div>
                  <div className='text-muted'>Ensure both your face and the paper with the text are clearly visible to the webcam.</div>
                </div>
              </div>
              <VideoRecorder getverify={verifyVideo} randomNum={randomNum} getRandom={generateNewRand} />
            </div>
          </div>
        </div>
      </div> */}
      {/* Video Verification Modal */}
      <div className="video-verify-model modal fade" id="videoVerifyModel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={- 1} aria-labelledby="videoVerifyModelLabel" aria-hidden="true" >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content rounded-3">
            <div className="modal-body px-3 py-4 p-md-5 text-center">

              <div id='uniqueCode' className='mb-4'>
                <div className='fs-5 fw-bold mb-2'>Your Unique Code</div>
                <div className='text-muted mb-4'>Ensure both your face and the paper with the text are clearly visible to the webcam.</div>
                <div className='verification-code display-3 fw-bold mb-4 py-xl-4'>{randomNum}</div>
                <div className='mb-4 mb-md-5'>
                  <div className='text-muted'>Write it clearly on a blank page and show it in front of the webcam.</div>
                  <div className='text-muted'>Ensure both your face and the paper with the text are clearly visible to the webcam.</div>
                </div>
              </div>

              <VideoRecorder getverify={verifyVideo} randomNum={randomNum} getRandom={generateNewRand} />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
